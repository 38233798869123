import React, { useEffect, useRef, useCallback, useState } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"

import { getNavigationSidebar as onGetNavigationSidebar } from "store/actions"

import { isEmpty } from "lodash"
import { layoutTypes } from "constants/layout"
import { authProtectedRoutes } from "./../../routes"

const SidebarContent = props => {
  const ref = useRef()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(onGetNavigationSidebar())
  }, [dispatch])

  //////////////////PROGRAM SCHEDULE STATE/////////////////////
  const { navigations, loading } = useSelector(state => state.navigation)
  const { layoutType } = useSelector(state => state.Layout)
  //////////////////////////////////////

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.length && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()

  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    var item = document.getElementById("side-menu").getElementsByTagName("a")
    if (item.length > 0) {
      for (let i = 0; i < items.length; ++i) {
        const currentRoute = authProtectedRoutes.find(route => {
          const exactMatch = pathName === route.path
          const paramMatch =
            route.path.includes("/:") &&
            pathName.startsWith(route.path.split("/:")[0])
          return exactMatch || paramMatch
        })

        //console.log(pathName, items[i].pathname)
        // if (pathName === items[i].pathname) {
        //   matchingMenuItem = items[i]
        //   break
        // }

        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        } else {
          matchingMenuItem = Array.from(items).find(item => {
            const href = item.getAttribute("href")
            return currentRoute && href === currentRoute.ParentMenu
          })

          if (matchingMenuItem) {
            break
          }
        }
      }
    }

    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    if (!isEmpty(navigations) && layoutType === layoutTypes.VERTICAL) {
      const initMenu = () => {
        new MetisMenu("#side-menu")
        activeMenu()
      }
      initMenu()
    }
  }, [navigations, layoutType])

  useEffect(() => {
    if (!loading && !isEmpty(navigations)) {
      activeMenu()
    }
  }, [loading, navigations, activeMenu])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            {navigations.map((v, p) => {
              return (
                <div key={"top" + p}>
                  {!v.HasChilds ? (
                    <li key={"parent" + p}>
                      <Link to={v.Page}>
                        <i className={v.Icon}></i>
                        <span>{props.t(v.Module)}</span>
                      </Link>
                    </li>
                  ) : (
                    <li key={"parent" + p}>
                      <Link to="/#" className="has-arrow ">
                        <i className={v.Icon}></i>
                        <span>{props.t(v.Module)}</span>
                      </Link>
                      <ul className="sub-menu">
                        {v.ChildNavigation &&
                          v.ChildNavigation.map((r, s) => {
                            return (
                              <li key={"child" + s}>
                                <Link to={r.Page}>{props.t(r.Module)}</Link>
                              </li>
                            )
                          })}
                      </ul>
                    </li>
                  )}
                </div>
              )
            })}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))

/*Get PROGRAM */
export const GET_PROGRAMS = "GET_PROGRAMS"
export const GET_PROGRAMS_SUCCESS = "GET_PROGRAMS_SUCCESS"
export const GET_PROGRAMS_FAIL = "GET_PROGRAMS_FAIL"

/**
 * Delete PROGRAM
 */
export const DELETE_PROGRAM = "DELETE_PROGRAM"
export const DELETE_PROGRAM_SUCCESS = "DELETE_PROGRAM_SUCCESS"
export const DELETE_PROGRAM_FAIL = "DELETE_PROGRAM_FAIL"
/**
 * Add PROGRAM
 */
export const ADD_NEW_PROGRAM = "ADD_NEW_PROGRAM"
export const ADD_PROGRAM_SUCCESS = "ADD_PROGRAM_SUCCESS"
export const ADD_PROGRAM_FAIL = "ADD_PROGRAM_FAIL"

/**
 * Get PROGRAM DETAILS
 */
export const GET_PROGRAM_DETAIL = "GET_PROGRAM_DETAIL"
export const GET_PROGRAM_DETAIL_SUCCESS = "GET_PROGRAM_DETAIL_SUCCESS"
export const GET_PROGRAM_DETAIL_FAIL = "GET_PROGRAM_DETAIL_FAIL"

/**
 * Edit PROGRAM
 */
export const UPDATE_PROGRAM = "UPDATE_PROGRAM"
export const UPDATE_PROGRAM_SUCCESS = "UPDATE_PROGRAM_SUCCESS"
export const UPDATE_PROGRAM_FAIL = "UPDATE_PROGRAM_FAIL"

/**
 * Get PROGRAM SCHEDULE DETAIL
 */
export const GET_PROGRAM_SCHEDULE_DETAIL = "GET_PROGRAM_SCHEDULE_DETAIL"
export const GET_PROGRAM_SCHEDULE_DETAIL_SUCCESS =
  "GET_PROGRAM_SCHEDULE_DETAIL_SUCCESS"
export const GET_PROGRAM_SCHEDULE_DETAIL_FAIL =
  "GET_PROGRAM_SCHEDULE_DETAIL_FAIL"

/**
 * Add ENROLLMENT
 */
export const ADD_ENROLLMENT = "ADD_ENROLLMENT"
export const ADD_ENROLLMENT_SUCCESS = "ADD_ENROLLMENT_SUCCESS"
export const ADD_ENROLLMENT_FAIL = "ADD_ENROLLMENT_FAIL"

/** Get ENROLLMENTS*/
export const GET_ENROLLMENTS = "GET_ENROLLMENTS"
export const GET_ENROLLMENTS_SUCCESS = "GET_ENROLLMENTS_SUCCESS"
export const GET_ENROLLMENTS_FAIL = "GET_ENROLLMENTS_FAIL"

/** Get ENROLLMENT DETAIL*/
export const GET_ENROLLMENT_DETAIL = "GET_ENROLLMENT_DETAIL"
export const GET_ENROLLMENT_DETAIL_SUCCESS = "GET_ENROLLMENT_DETAIL_SUCCESS"
export const GET_ENROLLMENT_DETAIL_FAIL = "GET_ENROLLMENT_DETAIL_FAIL"

/** Update ENROLLMENT*/
export const UPDATE_ENROLLMENT = "UPDATE_ENROLLMENT"
export const UPDATE_ENROLLMENT_SUCCESS = "UPDATE_ENROLLMENT_SUCCESS"
export const UPDATE_ENROLLMENT_FAIL = "UPDATE_ENROLLMENT_FAIL"

//Reset PROGRAM
export const RESET_PROGRAM = "RESET_PROGRAM"

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//controls
import { Col, Label, Row, Input, FormFeedback, InputGroup } from "reactstrap"
import Flatpickr from "react-flatpickr"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

import { isEmpty } from "lodash"

import {
  addCharge as onAddCharge,
  updateCharge as onUpdateCharge,
} from "store/actions"
import PageModal from "components/Common/PageModal"
import Moment from "moment"

const formdefault = {
  ChargeID: 0,
  Name: "",
  Rate: "",
  IsPrivate: false,
  IsRegularProgram: false,
  IsVisit: false,
  IsRecurring: false,
}

const ChargeModal = ({ show, onEdit, charge, onCloseClick }) => {
  const dispatch = useDispatch()
  // const [types, setTypes] = useState([])
  //const [scheduleArray, setScheduleArray] = useState([])
  //const { charge } = useSelector(state => state.charge)

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    ChargeID: yup.number(),
    Name: yup.string().required("Required"),
    Value: yup.string().required("Required"),
    IsPrivate: yup.bool(),
    IsRegularProgram: yup.bool(),
    IsVisit: yup.bool(),
    IsRecurring: yup.bool(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, watch, trigger } =
    methods
  const { errors, isValid } = formState

  useEffect(() => {
    if (onEdit) {
      const response = {
        ...charge,
      }
      reset(response)
    } else {
      //setScheduleArray([])
      reset(formdefault)
    }
  }, [charge, onEdit])

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      //console.log(getValues())
      if (!onEdit) {
        dispatch(onAddCharge({ ...getValues(), ChargeTypeID: 3 }))
      } else {
        //console.log(getValues())
        dispatch(onUpdateCharge({ ...getValues(), ChargeTypeID: 3 }))
      }
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset(formdefault)
  }

  const isRegularProgramSelected = watch("IsRegularProgram")

  return (
    <PageModal
      show={show}
      onCloseClick={onCloseClick}
      onSaveClick={() => onSaveClick()}
      onClosed={() => onClosed()}
      header="Add charge"
      size="lg"
    >
      <FormProvider {...methods}>
        <Row>
          <Col lg={12}>
            <div className="mb-3">
              <Label>Name</Label>
              <Controller
                name="Name"
                control={control}
                render={({ field }) => (
                  <>
                    <Input
                      {...field}
                      id="Name"
                      type="text"
                      required
                      invalid={!!errors.Name}
                    />
                    {errors?.Name?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.Name?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
            <div className="mb-3">
              <Label>Value($)</Label>
              <Controller
                name="Value"
                control={control}
                render={({ field }) => (
                  <>
                    <Input
                      {...field}
                      id="Value"
                      type="text"
                      required
                      invalid={!!errors.Value}
                    />
                    {errors?.Value?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.Value?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
            <div className="mb-3">
              <Label>Make Private - (Will be visible to staff only)</Label>
              <div>
                <Controller
                  name="IsPrivate"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Switch
                        {...field}
                        id="IsPrivate"
                        checked={field.value}
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        className="me-1 mb-sm-8 mb-2"
                        onColor="#626ed4"
                      />
                    </>
                  )}
                />
              </div>
            </div>
            <div className="mb-3">
              <Label>Available for</Label>
              <div>
                <Controller
                  name="IsRegularProgram"
                  control={control}
                  render={({ field }) => (
                    <div className="form-check">
                      <input
                        {...field}
                        className="form-check-input"
                        type="checkbox"
                        id="IsRegularProgram"
                        defaultChecked={field.value}
                        onChange={e => {
                          field.onChange(e.target.checked)
                          if (!e.target.checked) {
                            setValue("IsRecurring", false)
                          }
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="IsRegularProgram"
                      >
                        Regular Program
                      </label>
                    </div>
                  )}
                />

                <Controller
                  name="IsVisit"
                  control={control}
                  render={({ field }) => (
                    <div className="form-check">
                      <input
                        {...field}
                        className="form-check-input"
                        type="checkbox"
                        id="IsVisit"
                        defaultChecked={field.value}
                        onChange={e => field.onChange(e.target.checked)}
                      />
                      <label className="form-check-label" htmlFor="IsVisit">
                        Visits
                      </label>
                    </div>
                  )}
                />
              </div>
            </div>
            {isRegularProgramSelected && (
              <div className="mb-3">
                <Label>Applied to</Label>
                <div>
                  <Controller
                    name="IsRecurring"
                    control={control}
                    render={({ field }) => (
                      <>
                        <div className="form-check">
                          <input
                            {...field}
                            className="form-check-input"
                            type="radio"
                            id="FirstBillCycle"
                            value="false"
                            defaultChecked={field.value === false}
                            onChange={() => field.onChange(false)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="FirstBillCycle"
                          >
                            Only First Billing Cycle
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            {...field}
                            className="form-check-input"
                            type="radio"
                            id="EachCycle"
                            value="true"
                            defaultChecked={field.value === true}
                            onChange={() => field.onChange(true)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="EachCycle"
                          >
                            Each Billing Cycle
                          </label>
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>
            )}
          </Col>
        </Row>
      </FormProvider>
    </PageModal>
  )
}

ChargeModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default ChargeModal

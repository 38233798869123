import React, { useEffect, useMemo, useState, Suspense } from "react"
import { useDeepCompareEffect } from "hooks"
import PropTypes from "prop-types"
import { Link, json, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"
import HappeningModal from "../modal/HappeningModal"
import {
  getServices as onGetServices,
  getEnrollments as onGetEnrollments,
} from "store/actions"
import Moment from "moment"
import Flatpickr from "react-flatpickr"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  Collapse,
  Table,
} from "reactstrap"

import Spinners from "components/Common/Spinner"
import DropdownButton from "components/Common/DropdownButton"
import EmptyContainer from "components/Common/EmptyContainer"
import EnrollModal from "../modal/EnrollModal"
import DailyVisitModal from "../modal/DailyVisitModal"
import HourlyVisitModal from "../modal/HourlyVisitModal"
import RecurringVisitModal from "../modal/RecurringVisitModal"
import ChargeModal from "../modal/ChargeModal"
import classnames from "classnames"
import { isEmpty } from "lodash"
import { FormatDate } from "helpers/method_helper"

function ServiceTab() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    enrollModal: false,
    dailyVisitModal: false,
    hourlyVisitModal: false,
    recurringVisitModal: false,
    chargeModal: false,
    objectsList: [],
  })

  const updateState = data => setState(previous => ({ ...previous, ...data }))

  //////////////////CHILD STATE/////////////////////
  const { child } = useSelector(state => state.child)
  //////////////////////////////////////

  //////////////////PROGRAM STATE//////////////////////
  const { enrollments } = useSelector(state => state.program)
  /////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(onGetServices())
    if (!isEmpty(child)) {
      dispatch(onGetEnrollments(child.ChildID))
    }
  }, [dispatch, child])

  useEffect(() => {
    if (!isEmpty(enrollments)) {
      updateState({
        objectsList: enrollments.map(e => ({ ...e, isOpen: false })),
      })
    }
  }, [enrollments])

  const handleClick = item => {
    updateState({
      objectsList: state.objectsList.map(e =>
        e.ProgramEnrollmentID === item.ProgramEnrollmentID
          ? { ...e, isOpen: !e.isOpen }
          : e
      ),
    })
  }

  const { services, loading } = useSelector(state => state.helper)
  const [isLoading, setLoading] = useState(loading)

  const onClick = item => {
    if (item.ServiceTypeID === 1) {
      updateState({ enrollModal: true })
    } else if (item.ServiceTypeID === 2) {
      updateState({ hourlyVisitModal: true })
    } else if (item.ServiceTypeID === 3) {
      updateState({ dailyVisitModal: true })
    } else if (item.ServiceTypeID === 4) {
      updateState({ recurringVisitModal: true })
    } else {
      updateState({ chargeModal: true })
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Date & time",
        accessor: "HappeningDate",
        Cell: cellProps => {
          return <>{cellProps.row.original.HappeningDate}</>
        },
      },
      {
        Header: "Action",
        accessor: "ShortName",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Details",
        accessor: "Details",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                // to={"/staff/" + cellProps.row.original.RowID}
                className="text-success"
                onClick={() => {
                  onClickEdit(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const onCloseModal = () => {
    updateState({
      enrollModal: false,
      hourlyVisitModal: false,
      dailyVisitModal: false,
      recurringVisitModal: false,
      chargeModal: false,
    })
  }

  return (
    <React.Fragment>
      {state.enrollModal && (
        <EnrollModal
          show={state.enrollModal}
          onCloseClick={() => onCloseModal()}
        />
      )}
      {state.dailyVisitModal && (
        <DailyVisitModal
          show={state.dailyVisitModal}
          onCloseClick={() => onCloseModal()}
        />
      )}
      {state.hourlyVisitModal && (
        <HourlyVisitModal
          show={state.hourlyVisitModal}
          onCloseClick={() => onCloseModal()}
        />
      )}
      {state.recurringVisitModal && (
        <RecurringVisitModal
          show={state.recurringVisitModal}
          onCloseClick={() => onCloseModal()}
        />
      )}
      {state.chargeModal && (
        <ChargeModal
          show={state.chargeModal}
          onCloseClick={() => onCloseModal()}
        />
      )}
      <div className="row mb-3">
        <div className="col text-sm-end">
          <DropdownButton
            label="Add"
            items={services}
            textField="ServiceType"
            valueField="ServiceTypeID"
            onSelectClick={e => onClick(e)}
          />
        </div>
      </div>
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <Row>
          <Col xs="12">
            {state.objectsList?.length == 0 ? (
              <EmptyContainer
                backURL=""
                message="No programs found for class"
                linkText=""
              />
            ) : (
              <div className="accordion" id="accordion">
                {state.objectsList &&
                  state.objectsList.map((v, p) => {
                    return (
                      <>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className={classnames(
                                "accordion-button",
                                "fw-medium",
                                {
                                  collapsed: !v.isOpen,
                                }
                              )}
                              type="button"
                              onClick={() => handleClick(v)}
                              style={{ cursor: "pointer" }}
                            >
                              {v.Program}
                              <Badge className="bg-success ms-1">
                                {v.BillingCycle}
                              </Badge>
                              <div className="font-weight-normal ms-2">
                                <span>
                                  {v.StartDate === v.EndDate
                                    ? `(${FormatDate(v.StartDate)})`
                                    : `(${
                                        FormatDate(v.StartDate) +
                                        " to " +
                                        FormatDate(v.EndDate)
                                      })`}
                                </span>
                              </div>
                              <Badge className="bg-secondary ms-1">
                                {v.EnrollmentTypeID === 3
                                  ? "Approved"
                                  : v.EnrollmentType}
                              </Badge>
                            </button>
                          </h2>
                          <Collapse
                            isOpen={v.isOpen}
                            className="accordion-collapse"
                          >
                            <div className="accordion-body">
                              <Table className="table mb-0 table">
                                <thead>
                                  <tr>
                                    <th>Day</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {v.EnrollmentTimes?.map((z, s) => {
                                    return (
                                      <tr key={"schedule" + s}>
                                        <td>{z.Day}</td>
                                        <td>
                                          {Moment(z.StartTime).format(
                                            "hh:mm A"
                                          )}
                                        </td>
                                        <td>
                                          {Moment(z.EndTime).format("hh:mm A")}
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          </Collapse>
                        </div>
                      </>
                    )
                  })}
              </div>
            )}
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}
ServiceTab.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default ServiceTab

import {
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  GET_STATES_SUCCESS,
  GET_STATES_FAIL,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAIL,
  GET_MEAL_TYPES_SUCCESS,
  GET_MEAL_TYPES_FAIL,
  GET_AVAILABLE_TYPES_SUCCESS,
  GET_AVAILABLE_TYPES_FAIL,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAIL,
  GET_SERVICE_CHARGES_SUCCESS,
  GET_SERVICE_CHARGES_FAIL,
  GET_AVAILABILITIES_SUCCESS,
  GET_AVAILABILITIES_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  countries: [],
  states: [],
  cities: [],
  mealtypes: [],
  availabletypes: [],
  services: [],
  addons: [],
  charges: [],
  availabilities: [],
  error: {},
  loading: true,
}

const Helper = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
        loading: true,
      }
    case GET_COUNTRIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_STATES_SUCCESS:
      return {
        ...state,
        states: action.payload,
        loading: true,
      }
    case GET_STATES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CITIES_SUCCESS:
      return {
        ...state,
        cities: action.payload,
        loading: true,
      }
    case GET_CITIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_MEAL_TYPES_SUCCESS:
      return {
        ...state,
        mealtypes: action.payload,
        loading: true,
      }
    case GET_MEAL_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_AVAILABLE_TYPES_SUCCESS:
      return {
        ...state,
        availabletypes: action.payload,
        loading: true,
      }
    case GET_AVAILABLE_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.payload,
        loading: true,
      }
    case GET_SERVICES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SERVICE_CHARGES_SUCCESS:
      return {
        ...state,
        addons: action.payload.AddOns,
        charges: action.payload.Charges,
        loading: true,
      }
    case GET_SERVICE_CHARGES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_AVAILABILITIES_SUCCESS:
      return {
        ...state,
        availabilities: action.payload,
        loading: true,
      }
    case GET_AVAILABILITIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Helper

import PropTypes from "prop-types"
import React, { useEffect } from "react"
//controls
import {
  Input,
  Col,
  Label,
  Row,
  Modal,
  ModalBody,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

import { updateProfile as onUpdateProfile } from "store/actions"

const ProfileModal = ({ show, onCloseClick }) => {
  const dispatch = useDispatch()

  //////////////////PROGRAM SCHEDULE STATE/////////////////////
  const { profile } = useSelector(state => state.centerProfile)
  //////////////////////////////////////

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    CenterProfileID: yup.number(),
    LongName: yup.string().required("Required"),
    ShortName: yup.string().nullable(),
    TaxID: yup.string().required("Required"),
    LegalName: yup.string().nullable(),
    LicenceCapacity: yup.number().nullable(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      CenterProfileID: 1,
      LongName: "",
      ShortName: "",
      TaxID: "",
      LegalName: "",
      LicenceCapacity: 0,
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    function updateState() {
      if (show) {
        reset(profile)
      }
    }

    updateState()
  }, [show, dispatch])

  const onSaveClick = () => {
    debugger
    trigger()
    //console.log(formState)
    if (isValid) {
      dispatch(onUpdateProfile(getValues()))
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        Edit Center Profile
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label>Center Name</Label>
                <Controller
                  name="LongName"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        id="LongName"
                        type="text"
                        required
                        invalid={!!errors.LongName}
                      />
                      {errors?.LongName?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.LongName?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Center Short Name</Label>
                <Controller
                  name="ShortName"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input {...field} id="ShortName" type="text" />
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Center Tax ID</Label>
                <Controller
                  name="TaxID"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        id="TaxID"
                        type="text"
                        required
                        invalid={!!errors.TaxID}
                      />
                      {errors?.TaxID?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.TaxID?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Center Legal Name</Label>
                <Controller
                  name="LegalName"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input {...field} id="LegalName" type="text" />
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Licensed Center Capacity</Label>
                <Controller
                  name="LicenceCapacity"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input {...field} id="LicenceCapacity" type="number" />
                    </>
                  )}
                />
              </div>
            </Col>
          </Row>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

ProfileModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
}

export default ProfileModal

import { call, put, takeEvery } from "redux-saga/effects"

// Meal Redux States
import {
  GET_COUNTRIES,
  GET_STATES,
  GET_CITIES,
  GET_MEAL_TYPES,
  GET_AVAILABLE_TYPES,
  GET_SERVICES,
  GET_SERVICE_CHARGES,
  GET_AVAILABILITIES,
} from "./actionTypes"
import {
  getCountriesSuccess,
  getCountriesFail,
  getStatesSuccess,
  getStatesFail,
  getCitiesSuccess,
  getCitiesFail,
  getMealTypesSuccess,
  getMealTypesFail,
  getAvailableTypesSuccess,
  getServicesSuccess,
  getAvailableTypesFail,
  getServicesFail,
  getServiceChargesSuccess,
  getServiceChargesFail,
  getAvailabilitiesSuccess,
  getAvailabilitiesFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCountries,
  getStates,
  getCities,
  getMealTypes,
  getServices,
  getServiceCharges,
  getAvailabilities,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchCountries() {
  try {
    const response = yield call(getCountries)
    yield put(getCountriesSuccess(response))
  } catch (error) {
    yield put(getCountriesFail(error))
  }
}

function* fetchStates({ payload: countryID }) {
  try {
    const response = yield call(getStates, countryID)
    yield put(getStatesSuccess(response))
  } catch (error) {
    yield put(getStatesFail(error))
  }
}

function* fetchCities({ payload: stateID }) {
  try {
    const response = yield call(getCities, stateID)
    yield put(getCitiesSuccess(response))
  } catch (error) {
    yield put(getCitiesFail(error))
  }
}

function* fetchMealTypes() {
  try {
    const response = yield call(getMealTypes)
    yield put(getMealTypesSuccess(response))
  } catch (error) {
    yield put(getMealTypesFail(error))
  }
}

function* fetchAvailableTypes() {
  try {
    const response = yield call(getAvailableTypes)
    yield put(getAvailableTypesSuccess(response))
  } catch (error) {
    yield put(getAvailableTypesFail(error))
  }
}

function* fetchServices() {
  try {
    const response = yield call(getServices)
    yield put(getServicesSuccess(response))
  } catch (error) {
    yield put(getServicesFail(error))
  }
}

function* fetchServiceCharges() {
  try {
    const response = yield call(getServiceCharges)
    yield put(getServiceChargesSuccess(response))
  } catch (error) {
    yield put(getServiceChargesFail(error))
  }
}

function* fetchAvailabilities() {
  try {
    const response = yield call(getAvailabilities)
    yield put(getAvailabilitiesSuccess(response))
  } catch (error) {
    yield put(getAvailabilitiesFail(error))
  }
}

function* helperSaga() {
  yield takeEvery(GET_COUNTRIES, fetchCountries)
  yield takeEvery(GET_STATES, fetchStates)
  yield takeEvery(GET_CITIES, fetchCities)
  yield takeEvery(GET_MEAL_TYPES, fetchMealTypes)
  yield takeEvery(GET_AVAILABLE_TYPES, fetchAvailableTypes)
  yield takeEvery(GET_SERVICES, fetchServices)
  yield takeEvery(GET_SERVICE_CHARGES, fetchServiceCharges)
  yield takeEvery(GET_AVAILABILITIES, fetchAvailabilities)
}

export default helperSaga

import React, { useEffect, useState } from "react"
import { Controller, useFormContext, useFieldArray } from "react-hook-form"
import Select from "react-select"
import { Input, Label, FormFeedback, Row, Col, Badge } from "reactstrap"
import Flatpickr from "react-flatpickr"

//redux
import { useSelector, useDispatch } from "react-redux"
import Moment from "moment"
import { isEmpty } from "lodash"

import {
  getChildren as onGetChildren,
  getClasses as onGetClasses,
  getServiceCharges as onGetServiceCharges,
} from "store/actions"

import TimesArray from "../../pages/Pricing/Program/modal/TimesArray"
import { useDeepCompareEffect } from "hooks"

const enrollmenttypes = [
  {
    EnrollmentTypeID: 1,
    EnrollmentType: "Requested",
  },
  {
    EnrollmentTypeID: 2,
    EnrollmentType: "Waitlist",
  },
  {
    EnrollmentTypeID: 3,
    EnrollmentType: "Enrollments",
  },
]

export default function EnrollmentFields(props) {
  const methods = useFormContext()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    actualAddons: [],
    actualCharges: [],
    actualCredits: [],
    selectedAddons: [],
    selectedCharges: [],
    selectedCredits: [],
    scheduleCount: 0,
    actualArray: [],
    didLoad: false,
  })

  const updateState = data => setState(previous => ({ ...previous, ...data }))

  const { control, formState, setValue, getValues, watch, reset } = methods
  const { errors } = formState

  const { fields, append, remove, update } = useFieldArray({
    name: "ProgramSchedules",
    control,
  })

  //////////////////PROGRAM STATE/////////////////////
  const { program } = useSelector(state => state.program)
  ////////////////////////////////////////////////////

  //////////////////CHILDREN STATE/////////////////////
  const { children } = useSelector(state => state.child)
  //////////////////////////////////////

  //////////////////CLASS STATE/////////////////////
  const { classes } = useSelector(state => state.class1)
  //////////////////////////////////////

  //////////////////HELPER STATE/////////////////////
  const { addons, charges } = useSelector(state => state.helper)
  //////////////////////////////////////

  //////////////////CHILD STATE/////////////////////
  const { child } = useSelector(state => state.child)
  //////////////////////////////////////

  useDeepCompareEffect(() => {
    dispatch(onGetChildren())
    dispatch(onGetClasses())
    dispatch(onGetServiceCharges())

    if (addons.length > 0) {
      updateState({
        actualAddons: addons.filter(x => x.IsRegularProgram === true),
      })
    }

    if (charges.length > 0) {
      updateState({
        actualCharges: charges.filter(
          x => x.ChargeTypeID === 3 && x.IsRegularProgram === true
        ),
        actualCredits: charges.filter(
          x => x.ChargeTypeID === 1 && x.IsRegularProgram === true
        ),
      })
    }

    updateState({
      selectedAddons: [],
      selectedCharges: [],
      selectedCredits: [],
    })

    if (!isEmpty(child)) {
      setValue("Children", [child])
      setValue("Classes", {
        ClassID: child.HomeClassID,
        Title: child.HomeClassTitle,
      })
    }
  }, [dispatch, addons, charges])

  const onAddonSelect = e => {
    const { value, checked } = e.target

    const existing = [...state.selectedAddons]
    if (checked) {
      existing.push(value)
      updateState({ selectedAddons: existing })
    } else {
      const updatedValue = existing.filter(x => {
        return x !== value
      })
      updateState({ selectedAddons: updatedValue })
    }
  }

  const onChargeSelect = e => {
    const { value, checked } = e.target

    const existing = [...state.selectedCharges]
    if (checked) {
      existing.push(value)
      updateState({ selectedCharges: existing })
    } else {
      const updatedValue = existing.filter(x => {
        return x !== value
      })
      updateState({ selectedCharges: updatedValue })
    }
  }

  const onCreditSelect = e => {
    const { value, checked } = e.target

    const existing = [...state.selectedCredits]
    if (checked) {
      existing.push(value)
      updateState({ selectedCredits: existing })
    } else {
      const updatedValue = existing.filter(x => {
        return x !== value
      })
      updateState({ selectedCredits: updatedValue })
    }
  }

  useEffect(() => {
    setValue("Addons", state.selectedAddons)
    setValue("Charges", state.selectedCharges)
    setValue("Credits", state.selectedCredits)
  }, [state])

  useEffect(() => {
    if (!state.didLoad && !isEmpty(program)) {
      updateState({ scheduleCount: program.ProgramSchedules[0].Days })
      for (let i = 0; i < program.WeekDays.length; i++) {
        append({
          DayID: program.WeekDays[i].DayID,
          Name: program.WeekDays[i].Name,
          IsLinked: false,
          Times: [
            {
              StartTime: "",
              EndTime: "",
            },
          ],
        })
      }
      updateState({ didLoad: true })
    }
  }, [program])

  function onCheckBoxClick(e) {
    const isChecked = e.target.checked
    if (isChecked) {
      updateState({ actualArray: [...state.actualArray, e.target.value] })
    } else {
      let index = state.actualArray.indexOf(e.target.value)
      state.actualArray.splice(index, 1)
      updateState({ actualArray: state.actualArray })
    }
  }

  useEffect(() => {
    if (state.actualArray.length > 0) {
      if (state.actualArray.length > state.scheduleCount) {
        setValue("IsSchedulePass", "")
      } else {
        setValue("IsSchedulePass", "pass")
      }
    } else {
      setValue("IsSchedulePass", "")
    }
  }, [state.actualArray.length, state.scheduleCount])

  return (
    <Row>
      <Col lg={12} className="mb-3">
        <h4>
          {program.Name +
            " " +
            program.ProgramSchedules[0].Days +
            (program.ProgramSchedules[0].Days > 1 ? " days" : " day")}
          <Badge className="bg-success ms-1">{program.BillingCycle}</Badge>
          <div>
            {`Price - $` +
              Math.round(program.ProgramSchedules[0].Price).toFixed(2)}
          </div>
        </h4>
        <div>
          You can pick{" "}
          {program.ProgramSchedules[0].Days +
            (program.ProgramSchedules[0].Days > 1 ? " days" : " day")}{" "}
          a week within{" "}
          {program.WeekDays.map((f, i) => (
            <React.Fragment key={`weekday-${i}`}>
              {f.Name}
              {i !== program.WeekDays.length - 1 && ", "}
            </React.Fragment>
          ))}
          between
          <span>
            {` from ` +
              Moment(program.StartTime).format("hh:mm A") +
              " to " +
              Moment(program.EndTime).format("hh:mm A")}
          </span>
        </div>
      </Col>

      <Col lg={12} hidden={props.ProgramSelected}>
        <div className="mb-3">
          <Label>Children</Label>
          <Controller
            name="Children"
            control={control}
            render={({ field }) => (
              <>
                <Select
                  {...field}
                  id="Children"
                  options={children}
                  getOptionLabel={option => option.Name}
                  getOptionValue={option => option.ChildID}
                  required
                  isMulti
                  onChange={(e, { value }) => {
                    const values = e.map(v =>
                      children.find(item => item.ChildID === v.ChildID)
                    )
                    setValue("Children", values)
                  }}
                  aria-invalid={!!errors.Children}
                  classNamePrefix="select2-selection"
                />
                {errors?.Children?.message && (
                  <FormFeedback type="invalid" className="d-block">
                    {errors.Children.message}
                  </FormFeedback>
                )}
              </>
            )}
          />
        </div>
      </Col>

      <Col lg={12}>
        <div className="mb-3">
          {(fields || []).map((s, i) => (
            <React.Fragment key={`schedule-fragment-${i}`}>
              <div className="row p-1 my-3">
                <div className="col-9 d-flex flex-column justify-content-center">
                  <label
                    className="cursor-pointer checkbox mb-0 align-items-start h5 font-weight-normal"
                    htmlFor={`dvCheck${i}`}
                  >
                    <Controller
                      control={control}
                      name={`ProgramSchedules[${i}].IsLinked`}
                      render={({ field }) => (
                        <input
                          {...field}
                          value={s.DayID}
                          id={`ProgramSchedules[${i}].IsLinked`}
                          type="checkbox"
                          className="form-check-input checkbox-medium me-2"
                          onClick={onCheckBoxClick}
                        />
                      )}
                    />
                    <span className="font-weight-normal">{s.Name}</span>
                  </label>
                </div>
              </div>
              {watch(`ProgramSchedules[${i}].IsLinked`) && (
                <TimesArray key={`timesarray-${i}`} nestIndex={i} />
              )}
            </React.Fragment>
          ))}

          {errors?.IsSchedulePass?.message && (
            <FormFeedback type="invalid" className="d-block alert alert-danger">
              <h6>
                You've selected {state.actualArray.length} days for an{" "}
                {state.scheduleCount} day program
              </h6>
            </FormFeedback>
          )}
        </div>
      </Col>

      <Col sm="6">
        <div className="mb-3">
          <Label>Start Date</Label>
          <Controller
            name="StartDate"
            control={control}
            render={({ field }) => (
              <>
                <Flatpickr
                  {...field}
                  className="form-control d-block"
                  id="StartDate"
                  options={{
                    dateFormat: "d M, Y",
                  }}
                  onChange={(selectedDates, dateStr, instance) => {
                    setValue("StartDate", dateStr)
                  }}
                  required
                />
                {errors?.StartDate?.message && (
                  <FormFeedback type="invalid" className="d-block">
                    {errors.StartDate.message}
                  </FormFeedback>
                )}
              </>
            )}
          />
        </div>
      </Col>

      <Col sm="6">
        <div className="mb-3">
          <Label>End Date</Label>
          <Controller
            name="EndDate"
            control={control}
            render={({ field }) => (
              <>
                <Flatpickr
                  {...field}
                  className="form-control d-block"
                  id="EndDate"
                  options={{
                    dateFormat: "d M, Y",
                  }}
                  onChange={(selectedDates, dateStr, instance) => {
                    setValue("EndDate", dateStr)
                  }}
                  required
                />
                {errors?.EndDate?.message && (
                  <FormFeedback type="invalid" className="d-block">
                    {errors.EndDate.message}
                  </FormFeedback>
                )}
              </>
            )}
          />
        </div>
      </Col>

      {state.actualAddons && state.actualAddons.length > 0 && (
        <div className="mb-3">
          <Label>Add-Ons</Label>
          {state.actualAddons.map((type, i) => (
            <div
              className="form-check form-check-primary d-flex align-items-center mb-3"
              key={`addone-${type.AddOnID}-${i}`}
            >
              <input
                type="checkbox"
                className="form-check-input checkbox-medium me-2"
                value={type.AddOnID}
                id={`dvAddon${i}`}
                onChange={onAddonSelect}
                autoComplete="off"
              />
              <label className="form-check-label" htmlFor={`dvAddon${i}`}>
                {type.Name}
                {` - $${type.Rate.toFixed(2)}`}
              </label>
            </div>
          ))}
        </div>
      )}

      {state.actualCharges && state.actualCharges.length > 0 && (
        <div className="mb-3">
          <Label>Charges</Label>
          {state.actualCharges.map((type, i) => (
            <div
              className="form-check form-check-primary d-flex align-items-center mb-3"
              key={`charge-${type.ChargeID}-${i}`}
            >
              <input
                type="checkbox"
                className="form-check-input checkbox-medium me-2"
                value={type.ChargeID}
                id={`dvCharge${i}`}
                onChange={onChargeSelect}
                autoComplete="off"
              />
              <label className="form-check-label" htmlFor={`dvCharge${i}`}>
                {type.Name}
                {` - $${type.Value.toFixed(2)}`}
                <div className="small">
                  {type.IsRecurring
                    ? "(Each billing cycle)"
                    : "(Only first billing cycle)"}
                </div>
              </label>
            </div>
          ))}
        </div>
      )}

      {state.actualCredits && state.actualCredits.length > 0 && (
        <div className="mb-3">
          <Label>Credits</Label>
          {state.actualCredits.map((type, i) => (
            <div
              className="form-check form-check-primary d-flex align-items-center mb-3"
              key={`credit-${type.ChargeID}-${i}`}
            >
              <input
                type="checkbox"
                className="form-check-input checkbox-medium me-2"
                value={type.ChargeID}
                id={`dvCredit${i}`}
                onChange={onCreditSelect}
                autoComplete="off"
              />
              <label className="form-check-label" htmlFor={`dvCredit${i}`}>
                {type.Name}
                {` - $${type.Value.toFixed(2)}`}{" "}
                <div className="small">
                  {type.IsRecurring
                    ? "(Each billing cycle)"
                    : "(Only first billing cycle)"}
                </div>
              </label>
            </div>
          ))}
        </div>
      )}

      <div className="mb-3">
        <Label>Assign to Class</Label>
        <Controller
          name="Classes"
          control={control}
          render={({ field }) => (
            <>
              <Select
                {...field}
                id="Classes"
                options={classes}
                getOptionLabel={option => option.Title}
                getOptionValue={option => option.ClassID}
                required
                aria-invalid={!!errors.Classes}
                classNamePrefix="select2-selection"
              />
              {errors?.Classes?.message && (
                <FormFeedback type="invalid" className="d-block">
                  {errors.Classes.message}
                </FormFeedback>
              )}
            </>
          )}
        />
      </div>

      <div className="mb-3">
        <Label>Enrollment Type</Label>
        <Controller
          name="EnrollmentTypes"
          control={control}
          render={({ field }) => (
            <>
              <Select
                {...field}
                id="EnrollmentTypes"
                options={enrollmenttypes}
                getOptionLabel={option => option.EnrollmentType}
                getOptionValue={option => option.EnrollmentTypeID}
                required
                aria-invalid={!!errors.EnrollmentTypes}
                classNamePrefix="select2-selection"
              />
              {errors?.EnrollmentTypes?.message && (
                <FormFeedback type="invalid" className="d-block">
                  {errors.EnrollmentTypes.message}
                </FormFeedback>
              )}
            </>
          )}
        />
      </div>
    </Row>
  )
}

//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_LOGIN = "/auth"
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//dashboard
export const DASHBOARD_URL = "/dashboard"
export const DASHBOARD_SCHEDULED_URL = "/dashboard/scheduled"
export const DASHBOARD_LIVE_URL = "/dashboard/live"

//Adults
export const ADULT_URL = "/adults"
export const ADD_NEW_ADULT = "/adults/saveadult"
export const ADULT_RELATIONS_URL = "/adults/relations"
export const ADULT_NOTES_URL = "/adults/notes"
export const ADD_CHILD_BY_ADULT = "/adults/savechild"

//Children
export const CHILD_URL = "/children"
export const ADD_NEW_CHILD = "/children/savechild"
export const CHILD_PROVIDED_FOOD_URL = "/children/providedfood"
export const CHILD_NOTES_URL = "/children/notes"
export const CHILD_AVAILABILITY_URL = "/children/availabilities"
export const CHILD_RELATIONS_URL = "/children/relations"
export const TAGS_URL = "/children/tag"
export const ADD_TAG_URL = "/children/savetag"

//Messages
export const MESSAGE_URL = "/messages"
export const MESSAGE_POSTS_URL = "/messages/posts"
export const ADD_NEW_MESSAGE_URL = "/messages/save"
export const AWAITED_MESSAGE_URL = "/messages/awaited"
export const APPROVAL_MESSAGE_URL = "/messages/approve"

//Child Schedules
export const ADD_CHILD_SCHEDULES_URL = "/childscheduling/assign"
export const CHILD_SCHEDULES_URL = "/childscheduling"

//Classes
export const CLASS_URL = "/classes"
export const ADD_NEW_CLASS = "/classes/saveclass"
export const ADD_CLASS_NOTES = "/classes/notes"
export const CLASS_PROGRAMS_URL = "/classes/programs"

//Class Happenings
export const CLASS_HAPPENING_URL = "/classhappenings"
export const CLASS_HAPPENING_TYPE_URL = "/classhappenings/happeningtypes"
export const ADD_CLASS_HAPPENING_URL = "/classhappenings/add"

//Class Schedules
export const ADD_CLASS_SCHEDULES_URL = "/classscheduling/assign"
export const CLASS_SCHEDULES_URL = "/classscheduling"

//ClassTypes
export const CLASS_TYPE_URL = "/classes/classtypes"

//Documents
export const DOCUMENT_URL = "/document"
export const DOWNLOAD_ARTIFACT_ATTACHMENT_URL = "/document/download"

//Meal
export const MEAL_URL = "/meals"
export const ADD_NEW_MEAL = "/meals/savemeal"
//Meal Schedules
export const ADD_MEAL_SCHEDULES_URL = "/mealscheduling/assign"
export const MEAL_SCHEDULES_URL = "/mealscheduling"

//Bus
export const BUS_URL = "/buses"
export const ADD_NEW_BUS = "/buses/savebus"
//Bus Schedules
export const ADD_BUS_SCHEDULES_URL = "/busscheduling/assign"
export const BUS_SCHEDULES_URL = "/busscheduling"
export const CHILD_BUS_SCHEDULES_URL = "/busscheduling/child"
export const STAFF_BUS_SCHEDULES_URL = "/busscheduling/staff"

//Room
export const ROOM_URL = "/rooms"
export const ADD_NEW_ROOM = "/rooms/saveroom"

//Program
export const PROGRAM_URL = "/pricing/programs"
export const ADD_NEW_PROGRAM = "pricing/programs/save"

export const GET_CALENDAR_PROGRAMS_ENROLLMENT_SCHEDULE_URL =
  "/pricing/getcalendarprogramenrollmentschedules"

export const GET_CALENDAR_PROGRAMS_ENROLLMENT_SCHEDULE_DETAIL_URL =
  "/pricing/getcalendarprogramenrollmentscheduledetails"

//Flexible
export const FLEXIBLE_URL = "/pricing/flexibles"
export const ADD_NEW_FLEXIBLE = "pricing/flexibles/save"
export const UPDATE_FLEXIBLE_SWITCH = "pricing/flexibles/switch"
export const UPDATE_FLEXIBLE_DEFAULT_RATE = "pricing/flexibles/defaultrate"

//AddOns
export const ADDON_URL = "/pricing/addons"
export const ADD_ADDON = "pricing/addons/save"

//Charges
export const CHARGE_URL = "/pricing/charges"
export const ADD_CHARGE = "pricing/charges/save"
export const CHARGETYPE_URL = "pricing/chargetypes"

//Navigation
export const NAVIGATION_URL = "/navigation"

//Enrollment
export const ENROLLMENT_URL = "/pricing/enrollments"
export const ENROLLMENT_DETAIL_URL = "/pricing/enrollments/detail"
export const ADD_ENROLLMENT = "pricing/enrollments/save"
export const UPDATE_ENROLLMENT = "pricing/enrollments/update"

//Staff
export const STAFF_URL = "/staffs"
export const ADD_NEW_STAFF = "/staffs/savestaff"

//ROLES
export const ROLE_URL = "/roles"
export const ROLE_TYPES_URL = "/roles/types"

//Staff Schedules
export const ADD_STAFF_SCHEDULES_URL = "/staffscheduling/assign"
export const STAFF_SCHEDULES_URL = "/staffscheduling"

//Photo
export const PHOTO_URL = "/upload"

//Payrate
export const PAYRATE_URL = "/payrates"
export const ADD_NEW_PAYRATE = "/payrates/savepayrate"
export const PAYRATE_DETAIL_URL = "/getpayrate"

//employeementtypes
export const EMPLOYEEMENT_TYPE_URL = "/payrates/employmenttypes"

//ClassificationTypes
export const CLASSIFICATION_TYPE_URL = "/payrates/classificationtypes"

/*Common helper functions */
export const GET_SCHEDULE_TYPES_URL = "/helper/scheduletypes"
export const GET_ABSENT_TYPES_URL = "/helper/absenttypes"
export const GET_RELATION_TYPES_URL = "/helper/relationtypes"
export const GET_PERMISSION_TYPES_URL = "/helper/permissiontypes"
export const GET_NOTIFICATION_EVENTS_URL = "/helper/notificationevents"
export const GET_NOTIFICATION_RECIPIENTS_URL = "/helper/notificationrecipients"
export const GET_FORUM_VISIBILITY_TYPES = "/helper/forumvisibilitytypes"
export const GET_TAG_CATEGORY = "/helper/tagcategories"
export const GET_PRIVACY_TYPES_URL = "/helper/privacytypes"
export const PAYMENT_METHOD_URL = "/helper/paymentmethods"
export const COUNTRY_URL = "/helper/countries"
export const STATE_URL = "/helper/states"
export const CITY_URL = "/helper/cities"
export const MEAL_TYPES_URL = "/helper/mealtypes"
export const AVAILABLE_TYPES_URL = "/helper/availabletypes"
export const SERVICES_URL = "/helper/services"
export const SERVICE_CHARGES_URL = "/helper/servicecharges"
export const AVAILABILITIES_URL = "/helper/availabilities"

//Staff Availability
export const STAFF_AVAILABILITY_URL = "/availabilities"

//Staff Profile
export const STAFF_PROFILE_URL = "/staffs/profile"

//Certificate
export const CERTIFICATE_URL = "/certifications"
export const CERTIFICATE_SAVE_URL = "/certifications/save"
export const CERTIFICATE_UPDATE_URL = "/certifications/update"
export const GET_CERTIFICATE_DAYS_URL = "/certifications/requireddays"
export const STAFF_CERTIFICATION_URL = "/certifications/staffcertification"

//Immunization
export const IMMUNIZATION_URL = "/immunizations"
export const IMMUNIZATION_SAVE_URL = "/immunizations/save"
export const IMMUNIZATION_UPDATE_URL = "/immunizations/update"
export const IMMUNIZATION_CHILD_URL = "/immunizations/childimmunization"

//Staff Happening
export const HAPPENING_TYPE_URL = "/staffhappenings/happeningtypes"
export const GET_STAFF_HAPPENING_URL = "/staffhappenings"
export const ADD_STAFF_HAPPENING = "/staffhappenings/addstaffhappening"
export const STAFF_HAPPENING_URL = "/staffhappenings"
export const HOUR_TYPE_URL = "/staffhappenings/hourtypes"

//Notifications
export const NOTIFICATION_URL = "/notifications"
export const ADD_NEW_NOTIFICATION = "/notifications/savenotification"

//Forums
export const FORUM_URL = "/forums"
export const ADD_NEW_FORUM = "/forums/saveforum"
export const ADD_NEW_FORUM_POST = "/forums/saveforumpost"
export const DELETE_FORUM_POST = "/forums/deleteforumpost"

//Chat Message
export const CHAT_URL = "/chats"
export const ADD_NEW_CHAT = "/chats/savechat"
export const ADD_NEW_CHAT_MESSAGE = "/chats/savechatmessage"
export const DELETE_CHAT_MESSAGE = "/chats/deletechatmessage"
export const LATEST_CHAT_URL = "/chats/latest"

export const GET_GENDER_URL = "/helper/genders"

//Child Happening
//export const HAPPENING_TYPE_URL = "/childhappenings/happeningtypes"
export const GET_CHILD_HAPPENING_URL = "/childhappenings"
export const ADD_CHILD_HAPPENING = "/childhappenings/addchildhappening"
export const CHILD_HAPPENING_URL = "/childhappenings"
//export const HOUR_TYPE_URL = "/staffhappenings/hourtypes"

//Center Profile
export const CENTER_PROFILE_URL = "/centerprofile"
export const CENTER_PROFILE_UPDATE_URL = "/centerprofile/center"
export const CENTER_PROFILE_ADDRESS_UPDATE_URL = "/centerprofile/address"
export const CENTER_PROFILE_CONTACT_UPDATE_URL = "/centerprofile/contact"
export const CENTER_PROFILE_CLOSING_UPDATE_URL = "/centerprofile/closing"
export const CENTER_PROFILE_OPEATING_HOUR_UPDATE_URL =
  "/centerprofile/availability"

//Bus
export const ALBUM_URL = "/albums"
export const ADD_NEW_ALBUM_URL = "/albums/savealbum"
export const ADD_NEW_ALBUMMEDIA_URL = "/albums/savealbummedia"
export const DELETE_ALBUMMEDIA_URL = "/albums/deletealbummedia"

//Lessons
export const LESSON_PLAN_URL = "/lessonplans"
export const LESSON_PLAN_DETAIL_URL = "/lessonplans/detail"
export const ADD_NEW_LESSON_PLAN = "/lessonplans/savelessonplan"
export const ADD_NEW_LESSON_PLAN_SCHEDULE =
  "/lessonplans/savelessonplanschedules"
export const UPDATE_LESSON_PLAN_SCHEDULE =
  "/lessonplans/updatelessonplanschedules"
export const LESSON_PLAN_SCHEDULE_URL = "/lessonplans/getlessonplanschedules"
export const LESSON_PLAN_SCHEDULE_DETAIL_URL =
  "/notes/getlessonplanscheduledetails"
export const LESSON_PLAN_SCHEDULE_DETAIL_UPDATE_URL =
  "/lessonplans/updatelessonplanscheduledetail"
export const LESSON_PLAN_SCHEDULE_DELETE_URL =
  "/lessonplans/deletelessonplanschedule"
export const LESSON_PLAN_SCHEDULE_DETAIL_DELETE_URL =
  "/lessonplans/deletelessonplanscheduledetails"
export const LESSON_PLAN_ENROLL_URL = "/lessonplans/getenrolllessonplans"

//Curriculums
export const CURRICULUM_URL = "/curriculums"
export const CURRICULUM_DETAIL_URL = "/curriculums/detail"
export const ADD_NEW_CURRICULUM = "/curriculums/savecurriculum"
export const ADD_NEW_CURRICULUM_SCHEDULE =
  "/curriculums/savecurriculumschedules"
export const UPDATE_CURRICULUM_SCHEDULE =
  "/curriculums/updatecurriculumschedules"
export const CURRICULUM_SCHEDULE_URL = "/curriculums/getcurriculumschedules"
export const CURRICULUM_SCHEDULE_DETAIL_URL =
  "/curriculums/getcurriculumscheduledetails"
export const CURRICULUM_SCHEDULE_DETAIL_UPDATE_URL =
  "/curriculums/updatecurriculumscheduledetail"
export const CURRICULUM_SCHEDULE_DELETE_URL =
  "/curriculums/deletecurriculumschedule"
export const CURRICULUM_SCHEDULE_DETAIL_DELETE_URL =
  "/curriculums/deletecurriculumscheduledetails"
export const CURRICULUM_ENROLL_URL = "/curriculums/getenrollcurriculums"

//Notes
export const NOTE_URL = "/notes"
export const NOTE_DETAIL_URL = "/notes/detail"
export const ADD_NEW_NOTE = "/notes/savenote"
export const ADD_NEW_NOTE_SCHEDULE = "/notes/savenoteschedules"
export const UPDATE_NOTE_SCHEDULE = "/notes/updatenoteschedules"
export const NOTE_SCHEDULE_URL = "/notes/getnoteschedules"
export const NOTE_SCHEDULE_DETAIL_URL = "/notes/getnotescheduledetails"
export const NOTE_SCHEDULE_DETAIL_UPDATE_URL = "/notes/updatenotescheduledetail"
export const NOTE_SCHEDULE_DELETE_URL = "/notes/deletenoteschedule"
export const NOTE_SCHEDULE_DETAIL_DELETE_URL =
  "/notes/deletenoteScheduleDetails"
export const NOTE_ENROLL_URL = "/notes/GetEnrollNotes"
export const NOTE_CATEGORIES_URL = "/notes/getnotecategories"

//Reports
export const REPORT_URL = "/reports"
export const REPORT_TYPE_URL = "/reports/reporttypes"

//Users
export const USER_URL = "/users"
export const CHANGE_PASSWORD_URL = "/users/changepassword"

//THEME
export const THEME_URL = "/themes"
export const UPDATE_THEME = "/themes/updatetheme"

//CALENDER
//export const GET_EVENTS = "/events"
export const GET_EVENTS = "/childscheduling/getschedules"

export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"

//Class Activities
export const ADD_ACTIVITIES_URL = "/activities/addactivity"
export const ACTIVITIES_URL = "/activities"
export const ADD_ACTIVITT_DETAIL_URL = "/activities/detail"
export const UPDATE_ACTIVITIES_URL = "/activities/updateactivity"
export const DOWNLOAD_ACTIVITY_ATTACHMENT_URL = "/activities/download"

//Wallet
export const WALLET_URL = "/wallet"
export const ADD_WALLET_URL = "/wallet/save"

//Fee
export const FEE_URL = "/fee"
export const ADD_FEE_URL = "/fee/save"
export const FEE_DETAIL_URL = "/fee/details"
export const PAID_FEE_URL = "/fee/feetransactions"

//Modules
export const ROLETYPE_MODULE_URL = "/module/roletypemodules"

//Impersonation
export const UPDATE_IMPERSONATE_USER = "/users/addimpersonate"
export const DELETE_IMPERSONATE_USER = "/users/removeimpersonate"
export const RESET_USER_PASSWORD_URL = "/users/ResetUserPassword"

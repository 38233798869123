import { call, put, takeEvery } from "redux-saga/effects"

// Addon Redux States
import {
  GET_ADDONS,
  DELETE_ADDON,
  ADD_ADDON,
  GET_ADDON_DETAIL,
  UPDATE_ADDON,
} from "./actionTypes"
import {
  getAddOnsSuccess,
  getAddOnsFail,
  deleteAddOnSuccess,
  deleteAddOnFail,
  addAddOnSuccess,
  addAddOnFail,
  getAddOnDetailSuccess,
  getAddOnDetailFail,
  updateAddOnSuccess,
  updateAddOnFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getAddOns,
  deleteAddOn,
  addAddOn,
  getAddOnDetail,
  updateAddOn,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchAddons() {
  debugger
  try {
    const response = yield call(getAddOns)
    yield put(getAddOnsSuccess(response))
  } catch (error) {
    yield put(getAddOnsFail(error))
  }
}

function* onDeleteAddOn({ payload: addOnID }) {
  try {
    //console.log(addOnID)
    const response = yield call(deleteAddOn, addOnID)
    yield put(deleteAddOnSuccess(response))
    toast.success("AddOn deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteAddOnFail(error))
    toast.error("AddOn deletion failed", { autoClose: 2000 })
  }
}

function* onAddAddOn({ payload: addon }) {
  try {
    const response = yield call(addAddOn, addon)
    yield put(addAddOnSuccess(response[0]))
    toast.success("AddOn added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addAddOnFail(error))
    toast.error("AddOn addition failed", { autoClose: 2000 })
  }
}

function* fetchAddOnDetail({ RowID }) {
  debugger
  try {
    const response = yield call(getAddOnDetail, RowID)
    let result = response[0]
    result = {
      ...result,
    }
    yield put(getAddOnDetailSuccess(result))
  } catch (error) {
    yield put(getAddOnDetailFail(error))
  }
}

function* onUpdateAddOn({ payload: addon }) {
  try {
    const response = yield call(updateAddOn, addon)
    let result = response[0]

    yield put(updateAddOnSuccess(result))
    toast.success("AddOn updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateAddOnFail(error))
    toast.error("AddOn updation failed", { autoClose: 2000 })
  }
}

function* addonSaga() {
  yield takeEvery(GET_ADDONS, fetchAddons)
  yield takeEvery(DELETE_ADDON, onDeleteAddOn)
  yield takeEvery(ADD_ADDON, onAddAddOn)
  yield takeEvery(GET_ADDON_DETAIL, fetchAddOnDetail)
  yield takeEvery(UPDATE_ADDON, onUpdateAddOn)
}

export default addonSaga

import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as moment from "moment"
import { useDeepCompareEffect } from "hooks"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  getForums as onGetForums,
  deleteForum as onDeleteForum,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"

import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import { getAdultVisibility, getStaffVisibility } from "helpers/token_helper"
import { getAuthUser } from "helpers/token_helper"
import { EnumRoleTypes } from "helpers/enum_helper"
import { getForumVisibilityTypes } from "./../../helpers/backend_helper"

function Forum() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { forums, loading } = useSelector(state => state.forum)

  const [isLoading, setLoading] = useState(loading)
  const [deleteModal, setDeleteModal] = useState(false)
  const [forum, setForum] = useState(null)
  const [editable, setEditable] = useState(true)

  useEffect(() => {
    if (
      getAdultVisibility() ||
      getStaffVisibility([
        EnumRoleTypes.Teaching_Staff,
        EnumRoleTypes.Non_Teaching_Staff,
        EnumRoleTypes.Accountant,
      ])
    ) {
      setEditable(false)
    }

    if (forums && !forums.length) {
      dispatch(onGetForums())
    }

    //console.log(editable)
  }, [dispatch, forums])

  const handleAddClick = () => {
    navigate("/forum/new")
  }

  const onClickDelete = arg => {
    setForum(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (forum && forum.ForumID) {
      dispatch(onDeleteForum(forum.ForumID))
      setDeleteModal(false)
    }
  }

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  const ForumStatus = ({ status }) => {
    switch (status) {
      case true:
        return <Badge className="bg-success"> Active </Badge>
      case false:
        return <Badge className="bg-danger"> Inactive </Badge>
      default:
        return ""
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "Title",
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  className="text-dark"
                  to={"/discussion/" + cellProps.row.original.RowID}
                >
                  {cellProps.row.original.Title}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Visibility",
        accessor: "ForumVisibilityType",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Created By",
        accessor: "CreatedBy",
        Cell: cellProps => {
          return (
            <>
              <span className="font-size-14 mb-1">
                {cellProps.row.original.CreatedByName}
              </span>
              <p className="text-muted mb-0">
                {handleValidDate(cellProps.row.original.CreatedDate)}
              </p>
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "IsActive",
        Cell: cellProps => {
          return <ForumStatus status={cellProps.value} />
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {editable ? (
                <>
                  <Link
                    to={"/forum/" + cellProps.row.original.RowID}
                    className="text-success"
                  >
                    <i
                      className="mdi mdi-pencil font-size-18"
                      id="edittooltip"
                    />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                  <Link
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      onClickDelete(cellProps.row.original)
                    }}
                  >
                    <i
                      className="mdi mdi-delete font-size-18"
                      id="deletetooltip"
                    />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </>
              ) : (
                <Link
                  to={"/discussion/" + cellProps.row.original.RowID}
                  className="btn btn-primary btn-sm"
                >
                  View
                </Link>
              )}
            </div>
          )
        },
      },
    ],
    [editable]
  )
  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Forums" breadcrumbItem="Forum List" />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={
                        editable
                          ? forums
                          : forums.filter(
                              forum =>
                                forum.ForumVisibilityTypeID == 1 &&
                                forum.IsActive == 1
                            )
                      }
                      isGlobalFilter={true}
                      isAddOption={editable ? true : false}
                      addOptionText="Add forum"
                      handleAddClick={handleAddClick}
                      customPageSize={10}
                      isPagination={true}
                      filterable={false}
                      iscustomPageSizeOptions={true}
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination pagination-rounded justify-content-end mb-2"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}
Forum.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Forum

import PropTypes, { number, object } from "prop-types"
import React, { useEffect, useState } from "react"
//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Select from "react-select"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  addEnrollment as onAddEnrollment,
  getClasses as onGetClasses,
  getServiceCharges as onGetServiceCharges,
} from "store/actions"

//Date filter
import Moment from "moment"

import { isEmpty } from "lodash"
import { useDeepCompareEffect } from "hooks"
import TimesArray from "pages/Pricing/Program/modal/TimesArray"
import { EnumObjectTypes, EnumServiceTypes } from "helpers/enum_helper"
import ProfilePicture from "components/Common/ProfilePicture"
import { Calculate_Age } from "helpers/method_helper"

const formdefault = {
  ProgramID: 0,
  DayID: 1,
  ServiceTypeID: EnumServiceTypes.Hourly_Visit,
}

const enrollmenttypes = [
  {
    EnrollmentTypeID: 1,
    EnrollmentType: "Requested",
  },
  {
    EnrollmentTypeID: 2,
    EnrollmentType: "Waitlist",
  },
  {
    EnrollmentTypeID: 3,
    EnrollmentType: "Enrollments",
  },
]

const HourlyVisitModal = ({ show, onCloseClick }) => {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    actualAddons: [],
    actualCharges: [],
    actualCredits: [],
    selectedAddons: [],
    selectedCharges: [],
    selectedCredits: [],
  })

  const updateState = data => setState(previous => ({ ...previous, ...data }))

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    ProgramID: yup.number(),
    DayID: yup.number(),
    Children: yup
      .array()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
    ProgramSchedules: yup.array().of(
      yup.object().shape({
        DayID: yup.number(),
        IsLinked: yup.bool(),
        Times: yup.array().of(
          yup.object().shape({
            StartTime: yup.string().required("Required"),
            EndTime: yup.string().required("Required"),
          })
        ),
      })
    ),
    EnrollmentTypes: yup
      .object()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
    StartDate: yup.string().required("Required"),
    Classes: yup
      .object()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
    ServiceTypeID: yup.number(),
    AddOns: yup.array(),
    Charges: yup.array(),
    Credits: yup.array(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState

  //////////////////CLASS STATE/////////////////////
  const { classes } = useSelector(state => state.class1)
  //////////////////////////////////////

  //////////////////HELPER STATE/////////////////////
  const { addons, charges } = useSelector(state => state.helper)
  //////////////////////////////////////

  //////////////////CHILD STATE/////////////////////
  const { child } = useSelector(state => state.child)
  //////////////////////////////////////

  useDeepCompareEffect(() => {
    dispatch(onGetClasses())
    dispatch(onGetServiceCharges())

    if (addons.length > 0) {
      updateState({
        actualAddons: addons.filter(x => x.IsVisit === true),
      })
    }

    if (charges.length > 0) {
      updateState({
        actualCharges: charges.filter(
          x => x.ChargeTypeID === 3 && x.IsVisit === true
        ),
        actualCredits: charges.filter(
          x => x.ChargeTypeID === 1 && x.IsVisit === true
        ),
      })
    }

    updateState({
      selectedAddons: [],
      selectedCharges: [],
      selectedCredits: [],
    })

    if (!isEmpty(child)) {
      setValue("Children", [child])
      setValue("Classes", {
        ClassID: child.HomeClassID,
        Title: child.HomeClassTitle,
      })
    }
  }, [dispatch, addons, charges])

  const onAddonSelect = e => {
    const { value, checked } = e.target

    const existing = [...state.selectedAddons]
    if (checked) {
      existing.push(value)
      updateState({ selectedAddons: existing })
    } else {
      const updatedValue = existing.filter(x => {
        return x !== value
      })
      updateState({ selectedAddons: updatedValue })
    }
  }

  const onChargeSelect = e => {
    const { value, checked } = e.target

    const existing = [...state.selectedCharges]
    if (checked) {
      existing.push(value)
      updateState({ selectedCharges: existing })
    } else {
      const updatedValue = existing.filter(x => {
        return x !== value
      })
      updateState({ selectedCharges: updatedValue })
    }
  }

  const onCreditSelect = e => {
    const { value, checked } = e.target

    const existing = [...state.selectedCredits]
    if (checked) {
      existing.push(value)
      updateState({ selectedCredits: existing })
    } else {
      const updatedValue = existing.filter(x => {
        return x !== value
      })
      updateState({ selectedCredits: updatedValue })
    }
  }

  useEffect(() => {
    setValue("Addons", state.selectedAddons)
    setValue("Charges", state.selectedCharges)
    setValue("Credits", state.selectedCredits)
  }, [state])

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      setValue("ProgramID", 0)
      //console.log(getValues())
      dispatch(onAddEnrollment(getValues()))
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        Hourly Visit
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <Row>
            <Col sm="12">
              <div className="mb-3">
                <ProfilePicture
                  ObjectTypeID={EnumObjectTypes.Children}
                  RowID={child.RowID}
                  Title={child.Name}
                  SubTitle={Calculate_Age(child.DateOfBirth)}
                />
              </div>
              <div className="mb-3">
                <Label>Date</Label>
                <Controller
                  name="StartDate"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Flatpickr
                        {...field}
                        className="form-control d-block"
                        id="StartDate"
                        options={{
                          dateFormat: "d M, Y",
                        }}
                        onChange={(selectedDates, dateStr, instance) => {
                          setValue("StartDate", dateStr)
                          setValue(
                            "ProgramSchedules[0].DayID",
                            Moment(Date.parse(dateStr)).day() + 1
                          )
                          setValue("ProgramSchedules[0].IsLinked", true)
                        }}
                        required
                      />
                      {errors?.StartDate?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.StartDate?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Row>
                  <Col sm={6}>
                    <Label>Start Time</Label>
                    <Controller
                      name={`ProgramSchedules[0].Times[0].StartTime`}
                      control={control}
                      render={({ field }) => (
                        <>
                          <Flatpickr
                            {...field}
                            className="form-control d-block"
                            id={`ProgramSchedules[0].Times[0].StartTime`}
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: "h:i K",
                            }}
                            value={
                              field.value
                                ? Moment(field.value).format("h:mm a")
                                : ""
                            }
                            onChange={(selectedDates, dateStr, instance) => {
                              setValue(
                                `ProgramSchedules[0].Times[0].StartTime`,
                                selectedDates[0]
                              )
                            }}
                            required
                          />
                          {errors?.ProgramSchedules?.[0].Times?.[0].StartTime
                            ?.message ? (
                            <FormFeedback type="invalid" className="d-block">
                              {
                                errors?.ProgramSchedules?.[0].Times?.[0]
                                  .StartTime?.message
                              }
                            </FormFeedback>
                          ) : null}
                        </>
                      )}
                    />
                  </Col>
                  <Col sm={6}>
                    <Label>End Time</Label>
                    <Controller
                      control={control}
                      name={`ProgramSchedules[0].Times[0].EndTime`}
                      render={({ field }) => (
                        <>
                          <Flatpickr
                            {...field}
                            className="form-control d-block"
                            id={`ProgramSchedules[0].Times[0].EndTime`}
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: "h:i K",
                            }}
                            value={
                              field.value
                                ? Moment(field.value).format("h:mm a")
                                : ""
                            }
                            onChange={(selectedDates, dateStr, instance) => {
                              setValue(
                                `ProgramSchedules[0].Times[0].EndTime`,
                                selectedDates[0]
                              )
                            }}
                            required
                          />
                          {errors?.ProgramSchedules?.[0].Times?.[0].StartTime
                            ?.message ? (
                            <FormFeedback type="invalid" className="d-block">
                              {
                                errors?.ProgramSchedules?.[0].Times?.[0]
                                  .StartTime?.message
                              }
                            </FormFeedback>
                          ) : null}
                        </>
                      )}
                    />
                  </Col>
                </Row>
              </div>
              {state.actualAddons && state.actualAddons.length > 0 ? (
                <div className="mb-3">
                  <Label>Add-Ons</Label>
                  {(state.actualAddons || []).map((type, i) => (
                    <div
                      className="form-check form-check-primary d-flex align-items-center mb-3"
                      key={"addone" + i}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input checkbox-medium me-2"
                        value={type.AddOnID}
                        id={"dvAddon" + i}
                        onChange={onAddonSelect}
                        autoComplete="off"
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"dvAddon" + i}
                      >
                        {type.Name}
                        {` - $${type.Rate.toFixed(2)}`}
                      </label>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
              {state.actualCharges && state.actualCharges.length > 0 ? (
                <div className="mb-3">
                  <Label>Charges</Label>
                  {(state.actualCharges || []).map((type, i) => (
                    <div
                      className="form-check form-check-primary d-flex align-items-center mb-3"
                      key={"charge" + i}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input checkbox-medium me-2"
                        value={type.ChargeID}
                        id={"dvCharge" + i}
                        onChange={onChargeSelect}
                        autoComplete="off"
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"dvCharge" + i}
                      >
                        {type.Name}
                        {` - $${type.Value.toFixed(2)}`}
                        <div className="small">
                          {type.IsRecurring
                            ? "(Each billing cycle)"
                            : "(Only first billing cycle)"}
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
              {state.actualCredits && state.actualCredits.length > 0 ? (
                <div className="mb-3">
                  <Label>Credits</Label>
                  {(state.actualCredits || []).map((type, i) => (
                    <div
                      className="form-check form-check-primary d-flex align-items-center mb-3"
                      key={"credit" + i}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input checkbox-medium me-2"
                        value={type.ChargeID}
                        id={"dvCredit" + i}
                        onChange={onCreditSelect}
                        autoComplete="off"
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"dvCredit" + i}
                      >
                        {type.Name}
                        {` - $${type.Value.toFixed(2)}`}{" "}
                        <div className="small">
                          {type.IsRecurring
                            ? "(Each billing cycle)"
                            : "(Only first billing cycle)"}
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
              <div className="mb-3">
                <Label>Assign to Class</Label>
                <Controller
                  name="Classes"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id="Classes"
                        options={classes}
                        getOptionLabel={option => option.Title}
                        getOptionValue={option => option.ClassID}
                        required
                        aria-invalid={!!errors.Classes}
                        classNamePrefix="select2-selection"
                      />
                      {errors?.Classes?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.Classes?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Enrollment Type</Label>
                <Controller
                  name="EnrollmentTypes"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id="EnrollmentTypes"
                        options={enrollmenttypes}
                        getOptionLabel={option => option.EnrollmentType}
                        getOptionValue={option => option.EnrollmentTypeID}
                        required
                        aria-invalid={!!errors.EnrollmentTypes}
                        classNamePrefix="select2-selection"
                      />
                      {errors?.EnrollmentTypes?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.EnrollmentTypes?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
            </Col>
          </Row>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

HourlyVisitModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.bool,
}

export default HourlyVisitModal

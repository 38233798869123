import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Button,
  Card,
  CardBody,
  CardText,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
} from "reactstrap"

import BasicInfoTab from "./tabs/BasicInfoTab"
import {
  getAdultDetail as onGetAdultDetail,
  getWalletTransaction as onGetWalletTransaction,
  getFeeTransactionDetail as onGetFeeTransactionDetail,
} from "store/actions"
import { isEmpty } from "lodash"
import EmptyContainer from "components/Common/EmptyContainer"
import { useDeepCompareEffect } from "hooks"
import classnames from "classnames"
import { EnumObjectTypes } from "helpers/enum_helper"
import Document from "components/Common/Document"
import NotesModal from "./modal/NotesModal"
import WalletModal from "./modal/WalletModal"
import MoneyTab from "./tabs/MoneyTab"
import PaymentTab from "./tabs/PaymentTab"
import FamilyProfile from "./profile/FamilyProfile"

const AdultAddEdit = () => {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [header, setHeader] = useState("")
  const [tabValue, setTabValue] = useState("1")

  //////////////////Adult STATE/////////////////////
  const data = useSelector(state => state.adult)
  //////////////////////////////////////

  //////////////////Wallet STATE/////////////////////
  const { wallet } = useSelector(state => state.wallet)
  //////////////////////////////////////

  useDeepCompareEffect(() => {
    const { id } = routeParams

    if (id === "new") {
      setHeader("Add Adult")
    } else {
      setHeader("Edit Adult")
      dispatch(onGetAdultDetail(id))
    }
  }, [dispatch, routeParams])

  useEffect(() => {
    if (!isEmpty(data.adult)) {
      dispatch(onGetWalletTransaction(data.adult.AdultID))
      dispatch(onGetFeeTransactionDetail(data.adult.AdultID))
    }
  }, [dispatch, data])

  const toggleTab = tab => {
    if (tabValue !== tab) {
      setTabValue(tab)
    }
  }
  const NavItemTab = (navTitle, navValue) => {
    return (
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={classnames({
            active: tabValue === navValue,
          })}
          onClick={() => {
            toggleTab(navValue)
          }}
        >
          {navTitle}
        </NavLink>
      </NavItem>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Adult" breadcrumbItem={header} />
          {routeParams.id !== "new" && isEmpty(data.adult) ? (
            <EmptyContainer
              backURL="/adults"
              message="There is no such adult!"
              linkText="Go to Adults Page"
            />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <CardTitle>Basic Information</CardTitle>
                    <p className="card-title-desc mb-4">
                      Fill all information below
                    </p>
                    {routeParams.id !== "new" ? (
                      <Nav tabs className="nav-tabs-custom nav-justified">
                        {NavItemTab("Basic Info", "1")}
                        {NavItemTab("Misc", "2")}
                        {NavItemTab("Documents", "3")}
                        {NavItemTab("Payments", "4")}
                        {NavItemTab("Money", "5")}
                        {/* {NavItemTab("Messaging", "6")} */}
                      </Nav>
                    ) : (
                      ""
                    )}
                    <TabContent activeTab={tabValue} className="p-3 text-muted">
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <BasicInfoTab {...data} />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        {data.adult && (
                          <FamilyProfile rowId={data.adult.RowID} />
                        )}
                      </TabPane>
                      <TabPane tabId="3">
                        <Row>
                          <Col sm="12">
                            <Document
                              objectID={data.adult.AdultID}
                              objectTypeID={EnumObjectTypes.Adult}
                            />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="4">
                        <Row>
                          <Col sm="12">
                            <PaymentTab />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="5">
                        <Row>
                          <Col sm="12">
                            <MoneyTab />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="6">
                        <Row>
                          <Col sm="12"></Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AdultAddEdit

import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import * as moment from "moment"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"

import {
  getChargeTypes as onGetChargeTypes,
  getCharges as onGetCharges,
  getChargeDetail as onGetChargeDetail,
  deleteCharge as onDeleteCharge,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Col,
  Row,
  Table,
  Card,
  CardBody,
  Button,
  Badge,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import CreditModal from "./modal/CreditModal"
import ChargeModal from "./modal/ChargeModal"
import LatePaymentFeeModal from "./modal/LatePaymentFeeModal"
import { useDeepCompareEffect } from "hooks"
import Moment from "moment"
// import EnrollModal from "./modal/EnrollModal"

function Charge() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [toggleMenu, setToggleMenu] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [creditModal, setCreditModal] = useState(false)
  const [chargeModal, setChargeModal] = useState(false)
  const [latePaymentFeeModal, setLatePaymentFeeModal] = useState(false)
  const [chargeTypeID, setChargeTypeID] = useState(0)

  // const [enrollModal, setEnrollModal] = useState(false)
  const [charge, setCharge] = useState(false)
  const [edit, setEdit] = useState(false)

  useDeepCompareEffect(() => {
    dispatch(onGetChargeTypes())
    dispatch(onGetCharges())
  }, [dispatch])

  useEffect(() => {}, [chargeTypeID])
  //////////////////PROGRAM SCHEDULE STATE/////////////////////
  const { charges, chargeTypes, loading } = useSelector(state => state.charge)
  const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////

  const handleAddClick = () => {
    setCharge(null)
    setEdit(false)
    setChargeModal(true)
  }

  const onClickEdit = arg => {
    setCharge(arg)
    setEdit(true)
    setChargeTypeID(arg.ChargeTypeID)
    if (arg.ChargeTypeID === 1) {
      //Credit
      setCreditModal(true)
    } else if (arg.ChargeTypeID === 2) {
      // Late Payment Fee
      setLatePaymentFeeModal(true)
    } else if (arg.ChargeTypeID === 3) {
      //Charge
      setChargeModal(true)
    }
  }

  const onClickDelete = arg => {
    setCharge(arg)
    setDeleteModal(true)
  }

  const onCloseModal = () => {
    setCharge(null)
    setCreditModal(false)
    setChargeModal(false)
    setLatePaymentFeeModal(false)
  }

  const handleDelete = () => {
    if (charge && charge.ChargeID) {
      dispatch(onDeleteCharge(charge.ChargeID))
      setDeleteModal(false)
    }
  }

  const onChargeTypeClick = chargeType => {
    setCharge(null)
    setEdit(false)
    setCreditModal(false)
    setChargeModal(false)
    setLatePaymentFeeModal(false)
    setChargeTypeID(chargeType.ChargeTypeID)
    if (chargeType.ChargeTypeID === 1) {
      //Credit
      setCreditModal(true)
    } else if (chargeType.ChargeTypeID === 2) {
      // Late Payment Fee
      setLatePaymentFeeModal(true)
    } else if (chargeType.ChargeTypeID === 3) {
      //Charge
      setChargeModal(true)
    }
  }
  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
        Cell: cellProps => {
          return <>{cellProps.row.original.Name}</>
        },
      },
      // {
      //   Header: "Available for",
      //   accessor: "AvailableFor",
      //   Cell: cellProps => {
      //     return cellProps.value ? cellProps.value : ""
      //   },
      // },
      {
        Header: "Charge Type",
        accessor: "ChargeType",
        Cell: cellProps => {
          return <>{cellProps.row.original.ChargeType}</>
        },
      },

      {
        Header: "Value",
        accessor: "Value",
        Cell: cellProps => {
          return cellProps.value
            ? "$" + cellProps.value.toFixed(2).toString()
            : ""
        },
      },
      {
        Header: "Private",
        accessor: "IsPrivate",
        Cell: cellProps => {
          return cellProps.value == true ? "Yes" : "No"
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                // to={"/staff/" + cellProps.row.original.RowID}
                className="text-success"
                onClick={() => {
                  onClickEdit(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      {creditModal && (
        <CreditModal
          show={creditModal}
          onEdit={edit}
          charge={charge}
          onCloseClick={() => onCloseModal()}
        />
      )}
      {chargeModal && (
        <ChargeModal
          show={chargeModal}
          onEdit={edit}
          charge={charge}
          onCloseClick={() => onCloseModal()}
        />
      )}
      {latePaymentFeeModal && (
        <LatePaymentFeeModal
          show={latePaymentFeeModal}
          onEdit={edit}
          charge={charge}
          onCloseClick={() => onCloseModal()}
        />
      )}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Pricing" breadcrumbItem="Charges & credits" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div className="text-sm-end">
                    <Dropdown
                      isOpen={toggleMenu}
                      toggle={() => {
                        setToggleMenu(!toggleMenu)
                      }}
                      className="btn-group me-2 mb-2 mb-sm-0"
                    >
                      <DropdownToggle
                        className="btn btn-primary  dropdown-toggle"
                        tag="div"
                      >
                        Add <i className="mdi mdi-chevron-down ms-1" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        {chargeTypes &&
                          chargeTypes.map(chargeType =>
                            chargeType.ChargeTypeID != 5 ? (
                              <DropdownItem
                                key={chargeType.ChargeTypeID}
                                to="#"
                                onClick={() => {
                                  onChargeTypeClick(chargeType)
                                }}
                              >
                                {"New " + chargeType.ChargeType}
                              </DropdownItem>
                            ) : (
                              ""
                            )
                          )}
                      </DropdownMenu>
                    </Dropdown>
                    {/* <Button
                      type="button"
                      color="primary"
                      className="btn "
                      onClick={handleAddClick}
                    >
                      <i className="mdi mdi-plus-circle-outline me-1" />
                      Add Charges & credits
                    </Button> */}
                  </div>
                  {isLoading ? (
                    <Spinners setLoading={setLoading} />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={charges}
                      isGlobalFilter={true}
                      customPageSize={10}
                      isPagination={true}
                      filterable={false}
                      iscustomPageSizeOptions={true}
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination pagination-rounded justify-content-end mb-2"
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}
Charge.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Charge

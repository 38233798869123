import PropTypes, { number, object } from "prop-types"
import React, { useEffect, useState } from "react"
//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Select from "react-select"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  addEnrollment as onAddEnrollment,
  getServiceCharges as onGetServiceCharges,
} from "store/actions"

//Date filter
import Moment from "moment"

import { isEmpty } from "lodash"
import { useDeepCompareEffect } from "hooks"
import TimesArray from "pages/Pricing/Program/modal/TimesArray"
import { EnumObjectTypes, EnumServiceTypes } from "helpers/enum_helper"
import ProfilePicture from "components/Common/ProfilePicture"
import { Calculate_Age } from "helpers/method_helper"

const formdefault = {
  ProgramID: 0,
  DayID: 1,
  ServiceTypeID: EnumServiceTypes.Add_Charge,
}

const enrollmenttypes = [
  {
    EnrollmentTypeID: 1,
    EnrollmentType: "Requested",
  },
  {
    EnrollmentTypeID: 2,
    EnrollmentType: "Waitlist",
  },
  {
    EnrollmentTypeID: 3,
    EnrollmentType: "Enrollments",
  },
]

const ChargeModal = ({ show, onCloseClick }) => {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    selectedCharges: [],
  })

  const updateState = data => setState(previous => ({ ...previous, ...data }))

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    ProgramID: yup.number(),
    DayID: yup.number(),
    Children: yup.array(),
    ProgramSchedules: yup.array(),
    EnrollmentTypes: yup
      .object()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
    StartDate: yup.string().required("Required"),
    Classes: yup.object(),
    ServiceTypeID: yup.number(),
    ChargeTypes: yup.object(),
    Charges: yup.array().required("Required"),
    Description: yup.string(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState

  //////////////////HELPER STATE/////////////////////
  const { charges } = useSelector(state => state.helper)
  //////////////////////////////////////

  //////////////////CHILD STATE/////////////////////
  const { child } = useSelector(state => state.child)
  //////////////////////////////////////

  useDeepCompareEffect(() => {
    dispatch(onGetServiceCharges())

    if (!isEmpty(child)) {
      setValue("Children", [child])
      setValue("Classes", {
        ClassID: child.HomeClassID,
        Title: child.HomeClassTitle,
      })
      setValue("EnrollmentTypes", {
        EnrollmentTypeID: 1,
        EnrollmentType: "Requested",
      })
    }
  }, [dispatch])

  const onChargeSelect = e => {
    let existing = [...state.selectedCharges]
    existing = [] //clear last value
    existing.push(e.ChargeID)
    updateState({ selectedCharges: existing })
  }

  useEffect(() => {
    if (state.selectedCharges.length > 0) {
      setValue("Charges", state.selectedCharges)
    }
  }, [state])

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      //console.log(getValues())
      dispatch(onAddEnrollment(getValues()))
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        Add Charge
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <Row>
            <Col sm="12">
              <div className="mb-3">
                <ProfilePicture
                  ObjectTypeID={EnumObjectTypes.Children}
                  RowID={child.RowID}
                  Title={child.Name}
                  SubTitle={Calculate_Age(child.DateOfBirth)}
                />
              </div>
              <div className="mb-3">
                <Label>Charge</Label>
                <Controller
                  name="ChargeTypes"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id="ChargeTypes"
                        options={charges.filter(
                          x => x.ChargeTypeID === 2 || x.ChargeTypeID === 3
                        )}
                        getOptionLabel={option => (
                          <div>
                            <div className="d-flex justify-content-between">
                              <span>{option.Name}</span>
                              <span className="text-end">
                                ${option.Value.toFixed(2)}
                              </span>
                            </div>
                            <div className="small">
                              {option.ChargeTypeID === 2
                                ? "Late payment fee"
                                : ""}{" "}
                            </div>
                          </div>
                        )}
                        getOptionValue={option => option.ChargeID}
                        onChange={onChargeSelect}
                        aria-invalid={!!errors.ChargeTypes}
                        classNamePrefix="select2-selection"
                      />
                      {errors?.Charges?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.Charges?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Date</Label>
                <Controller
                  name="StartDate"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Flatpickr
                        {...field}
                        className="form-control d-block"
                        id="StartDate"
                        options={{
                          dateFormat: "d M, Y",
                        }}
                        onChange={(selectedDates, dateStr, instance) => {
                          setValue("StartDate", dateStr)
                        }}
                        required
                      />
                      {errors?.StartDate?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.StartDate?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Description</Label>
                <Controller
                  name="Description"
                  control={control}
                  value=""
                  render={({ field }) => (
                    <textarea
                      {...field}
                      rows={3}
                      className="form-control mb-3"
                      id="Description"
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

ChargeModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.bool,
}

export default ChargeModal

import React from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"

//import images
import avatar4 from "../../assets/images/users/avatar-4.jpg"

//swiper
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation, Autoplay } from "swiper/modules"
import "../../../node_modules/swiper/swiper.scss"

//redux
import { useSelector, useDispatch } from "react-redux"
import Moment from "moment"

const Birthdays = () => {
  const { birthday } = useSelector(state => state.Dashboard)

  const rows = birthday.reduce(function (rows, key, index) {
    return (
      (index % 2 == 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) &&
      rows
    )
  }, [])

  return (
    <React.Fragment>
      {rows && rows.length > 0 ? (
        <Card>
          <CardBody>
            <h4 className="card-title mb-3">Birthdays</h4>
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={50}
              slidesPerView={1}
              navigation
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
            >
              <div className="carousel-inner">
                {rows.map((row, i) => (
                  <SwiperSlide key={"swipe" + i}>
                    <div
                      className="carousel-item active"
                      data-bs-interval="3000"
                    >
                      {row.map((col, index) => (
                        <div
                          className={
                            "bg-light p-3 d-flex" +
                            (index == 0 ? " mb-3 rounded" : "")
                          }
                          key={"swipedata" + index}
                        >
                          <img
                            src={avatar4}
                            alt=""
                            className="avatar-sm rounded me-3"
                          />
                          <div className="flex-grow-1">
                            <h5 className="font-size-15 mb-2">
                              <a
                                href="candidate-overview"
                                className="text-body"
                              >
                                {col.Name}
                              </a>{" "}
                              <span className="badge badge-soft-info">
                                {col.Class}
                              </span>
                            </h5>
                            <p className="mb-0 text-muted">
                              <i className="bx bx-cake text-body align-middle"></i>{" "}
                              {Moment(col.BirthDate).format("d MMM yyyy")}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>
          </CardBody>
        </Card>
      ) : (
        ""
      )}
    </React.Fragment>
  )
}

export default Birthdays

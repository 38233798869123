/*Get COUNTRIES */
export const GET_COUNTRIES = "GET_COUNTRIES"
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS"
export const GET_COUNTRIES_FAIL = "GET_COUNTRIES_FAIL"

/*Get STATES */
export const GET_STATES = "GET_STATES"
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS"
export const GET_STATES_FAIL = "GET_STATES_FAIL"

/*Get CITIES */
export const GET_CITIES = "GET_CITIES"
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS"
export const GET_CITIES_FAIL = "GET_CITIES_FAIL"

/*Get MEAL TYPES */
export const GET_MEAL_TYPES = "GET_MEAL_TYPES"
export const GET_MEAL_TYPES_SUCCESS = "GET_MEAL_TYPES_SUCCESS"
export const GET_MEAL_TYPES_FAIL = "GET_MEAL_TYPES_FAIL"

/*Get AVAILABLE TYPES */
export const GET_AVAILABLE_TYPES = "GET_AVAILABLE_TYPES"
export const GET_AVAILABLE_TYPES_SUCCESS = "GET_AVAILABLE_TYPES_SUCCESS"
export const GET_AVAILABLE_TYPES_FAIL = "GET_AVAILABLE_TYPES_FAIL"

/*Get SERVICES */
export const GET_SERVICES = "GET_SERVICES"
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS"
export const GET_SERVICES_FAIL = "GET_SERVICES_FAIL"

/*Get SERVICE CHARGES */
export const GET_SERVICE_CHARGES = "GET_SERVICE_CHARGES"
export const GET_SERVICE_CHARGES_SUCCESS = "GET_SERVICE_CHARGES_SUCCESS"
export const GET_SERVICE_CHARGES_FAIL = "GET_SERVICE_CHARGES_FAIL"

/*Get AVAILABILITIES */
export const GET_AVAILABILITIES = "GET_AVAILABILITIES"
export const GET_AVAILABILITIES_SUCCESS = "GET_AVAILABILITIES_SUCCESS"
export const GET_AVAILABILITIES_FAIL = "GET_AVAILABILITIES_FAIL"

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//controls
import { Col, Label, Row, Input, FormFeedback, InputGroup } from "reactstrap"
import Flatpickr from "react-flatpickr"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

import { isEmpty } from "lodash"

import {
  addNewProgram as onAddNewProgram,
  updateProgram as onUpdateProgram,
} from "store/actions"
import PageModal from "components/Common/PageModal"
import Moment from "moment"

const daysofweek = [
  {
    DayID: 1,
    Name: "Sunday",
  },
  {
    DayID: 2,
    Name: "Monday",
  },
  {
    DayID: 3,
    Name: "Tuesday",
  },
  {
    DayID: 4,
    Name: "Wednesday",
  },
  {
    DayID: 5,
    Name: "Thursday",
  },
  {
    DayID: 6,
    Name: "Friday",
  },
  {
    DayID: 7,
    Name: "Saturday",
  },
]

const billingcycles = [
  {
    BillingCycleID: 1,
    BillingCycle: "Weekly",
  },
  {
    BillingCycleID: 2,
    BillingCycle: "Monthly",
  },
]

const formdefault = {
  ProgramID: 0,
  Name: "",
  IsPrivate: false,
  IsSpecificPeriod: false,
  WeekDays: [],
  ProgramSchedules: [],
  StartDate: "",
  EndDate: "",
}

const ProgramModal = ({ show, onEdit, onCloseClick }) => {
  const dispatch = useDispatch()
  const [types, setTypes] = useState([])
  const [scheduleArray, setScheduleArray] = useState([])
  const { program } = useSelector(state => state.program)

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    ProgramID: yup.number(),
    Name: yup.string().required("Required"),
    IsPrivate: yup.bool(),
    WeekDays: yup.array().required("Required"),
    StartTime: yup.string().required("Required"),
    EndTime: yup.string().required("Required"),
    IsSpecificPeriod: yup.bool(),
    StartDate: yup.string().when("IsSpecificPeriod", {
      is: value => value === true,
      then: schema => schema.required("Required"),
      otherwise: schema => schema.nullable(),
    }),
    EndDate: yup.string().when("IsSpecificPeriod", {
      is: value => value === true,
      then: schema => schema.required("Required"),
      otherwise: schema => schema.nullable(),
    }),
    MaxHoursPerDay: yup.number(),
    MaxHoursPerWeek: yup.number(),
    BillingCycles: yup
      .object()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
    ProgramSchedules: yup.array().of(
      yup.object().shape({
        ProgramScheduleID: yup.number(),
        ProgramID: yup.number(),
        IsLinked: yup.bool(),
        Days: yup.number(),
        Price: yup.number(),
      })
    ),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, watch, trigger } =
    methods
  const { errors, isValid } = formState

  const { fields, append, remove, update } = useFieldArray({
    name: "ProgramSchedules",
    control,
  })

  const IsSpecificPeriod = watch("IsSpecificPeriod")

  useEffect(() => {
    if (onEdit) {
      if (!isEmpty(program)) {
        const response = {
          ...program,
          BillingCycles: {
            BillingCycleID: program.BillingCycleID,
            BillingCycle: program.BillingCycle,
          },
          // StartTime:
          //   program.StartTime && Moment(program.StartTime).format("h:mm a"),
          // EndTime: program.EndTime && Moment(program.EndTime).format("h:mm a"),
          StartDate:
            program.StartDate &&
            Moment(program.StartDate).format("DD MMM yyyy"),
          EndDate:
            program.EndDate && Moment(program.EndDate).format("DD MMM yyyy"),
        }

        setScheduleArray(response.WeekDays)
        reset(response)
      }
    } else {
      setScheduleArray([])
      reset(formdefault)
    }
  }, [program, onEdit])

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      //console.log(getValues())
      if (!onEdit) {
        dispatch(onAddNewProgram(getValues()))
      } else {
        //console.log(getValues())
        dispatch(onUpdateProgram(getValues()))
      }
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset(formdefault)
  }

  function getDifference(array1, array2) {
    return array1.filter(o1 => {
      return !array2.some(o2 => {
        return o1 === o2.DayID
      })
    })
  }

  return (
    <PageModal
      show={show}
      onCloseClick={onCloseClick}
      onSaveClick={() => onSaveClick()}
      onClosed={() => onClosed()}
      header="Manage Program"
      size="lg"
    >
      <FormProvider {...methods}>
        <Row>
          <Col lg={12}>
            <div className="mb-3">
              <Label>Program Name</Label>
              <Controller
                name="Name"
                control={control}
                render={({ field }) => (
                  <>
                    <Input
                      {...field}
                      id="Name"
                      type="text"
                      required
                      invalid={!!errors.Name}
                    />
                    {errors?.Name?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.Name?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
            <div className="mb-3">
              <Label>Make Private - (Will be visible to staff only)</Label>
              <div>
                <Controller
                  name="IsPrivate"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Switch
                        {...field}
                        id="IsPrivate"
                        checked={field.value}
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        className="me-1 mb-sm-8 mb-2"
                        onColor="#626ed4"
                      />
                    </>
                  )}
                />
              </div>
            </div>
            <h5 className="mt-3 mb-3">Schedule</h5>
            <div className="mb-3">
              <Label>Weekdays</Label>
              <Controller
                name="WeekDays"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      id="WeekDays"
                      options={daysofweek}
                      getOptionLabel={option => option.Name}
                      getOptionValue={option => option.DayID}
                      required
                      isMulti
                      onChange={(e, { value }) => {
                        const values = e.map(v =>
                          daysofweek.find(item => item.DayID == v.DayID)
                        )
                        setValue("WeekDays", values)

                        const existing = [...scheduleArray]
                        for (let i = 0; i < values.length; i++) {
                          if (!existing.includes(values[i].DayID)) {
                            existing.push(values[i].DayID)
                            append({
                              ProgramScheduleID: 0,
                              ProgramID: 0,
                              IsLinked: false,
                              Days: i + 1,
                              Price: 0,
                            })
                            setScheduleArray(existing)
                          }
                        }

                        const diff = getDifference(existing, values)
                        for (let j = 0; j < diff.length; j++) {
                          const index = existing.indexOf(diff[j])
                          if (index > -1) {
                            existing.splice(index, 1)
                          }
                          setScheduleArray(existing)
                          remove(j)
                        }
                      }}
                      aria-invalid={!!errors.WeekDays}
                      classNamePrefix="select2-selection"
                    />
                    {errors?.WeekDays?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.WeekDays?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label>Time From</Label>
              <Controller
                name="StartTime"
                control={control}
                render={({ field }) => (
                  <>
                    <InputGroup>
                      <Flatpickr
                        {...field}
                        className="form-control d-block"
                        id="StartTime"
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "h:i K",
                        }}
                        value={
                          field.value
                            ? Moment(field.value).format("h:mm a")
                            : ""
                        }
                        onChange={(selectedDates, dateStr, instance) => {
                          setValue(
                            "StartTime",
                            Moment(selectedDates[0]).format("h:mm a")
                          )
                        }}
                        required
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <i className="mdi mdi-clock-outline" />
                        </span>
                      </div>
                      {errors?.StartTime?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.StartTime?.message}
                        </FormFeedback>
                      ) : null}
                    </InputGroup>
                  </>
                )}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label>Time To</Label>
              <Controller
                name="EndTime"
                control={control}
                render={({ field }) => (
                  <>
                    <InputGroup>
                      <Flatpickr
                        {...field}
                        className="form-control d-block"
                        id="EndTime"
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "h:i K",
                        }}
                        value={
                          field.value
                            ? Moment(field.value).format("h:mm a")
                            : ""
                        }
                        onChange={(selectedDates, dateStr, instance) => {
                          setValue(
                            "EndTime",
                            Moment(selectedDates[0]).format("h:mm a")
                          )
                        }}
                        required
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <i className="mdi mdi-clock-outline" />
                        </span>
                      </div>
                      {errors?.EndTime?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.EndTime?.message}
                        </FormFeedback>
                      ) : null}
                    </InputGroup>
                  </>
                )}
              />
            </div>
          </Col>
          <Col lg={12}>
            <div className="mb-3">
              <Label>Valid for a specific period of time only</Label>
              <div>
                <Controller
                  name="IsSpecificPeriod"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Switch
                        {...field}
                        id="IsSpecificPeriod"
                        checked={field.value}
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        className="me-1 mb-sm-8 mb-2"
                        onColor="#626ed4"
                      />
                    </>
                  )}
                />
              </div>
            </div>
          </Col>
          {IsSpecificPeriod ? (
            <>
              <Col sm="6">
                <div className="mb-3">
                  <Label>Start Date</Label>
                  <Controller
                    name="StartDate"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Flatpickr
                          {...field}
                          className="form-control d-block"
                          id="StartDate"
                          options={{
                            dateFormat: "d M, Y",
                          }}
                          onChange={(selectedDates, dateStr, instance) => {
                            setValue("StartDate", dateStr)
                          }}
                          required
                        />
                        {errors?.StartDate?.message ? (
                          <FormFeedback type="invalid" className="d-block">
                            {errors?.StartDate?.message}
                          </FormFeedback>
                        ) : null}
                      </>
                    )}
                  />
                </div>
              </Col>
              <Col sm="6">
                <div className="mb-3">
                  <Label>End Date</Label>
                  <Controller
                    name="EndDate"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Flatpickr
                          {...field}
                          className="form-control d-block"
                          id="EndDate"
                          options={{
                            dateFormat: "d M, Y",
                          }}
                          onChange={(selectedDates, dateStr, instance) => {
                            setValue("EndDate", dateStr)
                          }}
                          required
                        />
                        {errors?.EndDate?.message ? (
                          <FormFeedback type="invalid" className="d-block">
                            {errors?.EndDate?.message}
                          </FormFeedback>
                        ) : null}
                      </>
                    )}
                  />
                </div>
              </Col>
            </>
          ) : (
            ""
          )}
          <h5 className="mt-3">Max Usage Restrictions</h5>
          <p>
            If child's usage of this program exceeds specified values, they will
            be charged Hourly Attendence Rates for the overage. Empty field
            means no restriction.
          </p>
          <Col sm="6">
            <div className="mb-3">
              <Label>Max hours per day</Label>
              <Controller
                name="MaxHoursPerDay"
                control={control}
                render={({ field }) => (
                  <>
                    <Input
                      {...field}
                      id="MaxHoursPerDay"
                      type="number"
                      invalid={!!errors.MaxHoursPerDay}
                    />
                    {errors?.MaxHoursPerDay?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.MaxHoursPerDay?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
          </Col>
          <Col sm="6">
            <div className="mb-3">
              <Label>Max hours per week</Label>
              <Controller
                name="MaxHoursPerWeek"
                control={control}
                render={({ field }) => (
                  <>
                    <Input
                      {...field}
                      id="MaxHoursPerWeek"
                      type="number"
                      invalid={!!errors.MaxHoursPerWeek}
                    />
                    {errors?.MaxHoursPerWeek?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.MaxHoursPerWeek?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label>Billing Cycle</Label>
              <Controller
                name="BillingCycles"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      id="BillingCycles"
                      options={billingcycles}
                      getOptionLabel={option => option.BillingCycle}
                      getOptionValue={option => option.BillingCycleID}
                      required
                      aria-invalid={!!errors.BillingCycles}
                      classNamePrefix="select2-selection"
                    />
                    {errors?.BillingCycles?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.BillingCycles?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
          </Col>
          <Col lg={12}>
            <div className="mb-3">
              {(fields || []).map((s, i) => (
                <div key={"d" + i} className="row p-2 my-3 bg-light rounded">
                  <Controller
                    name={`ProgramSchedules[${i}].Days`}
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          {...field}
                          hidden
                          defaultValue={i + 1}
                          id={`ProgramSchedules[${i}].Days`}
                          type="number"
                        />
                      </>
                    )}
                  />
                  <div className="col-9 d-flex flex-column justify-content-center">
                    <label
                      className="cursor-pointer checkbox mb-0 align-items-start h5 font-weight-normal"
                      htmlFor={"dvCheck" + i}
                    >
                      <Controller
                        control={control}
                        name={`ProgramSchedules[${i}].IsLinked`}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              defaultChecked={field.value}
                              id={`ProgramSchedules[${i}].IsLinked`}
                              type="checkbox"
                              className="form-check-input checkbox-medium me-2"
                            />
                          </>
                        )}
                      />
                      <span className="font-weight-normal">
                        {i + 1 + (i === 0 ? " Day a week" : " Days a week")}
                      </span>
                    </label>
                  </div>
                  <div className="col-3">
                    <div className="form-group m-0">
                      <div className="d-flex justify-content-between"></div>
                      <div className="input-group">
                        <span className="form-control input-group-text d-flex justify-content-center bg-white">
                          $
                        </span>
                        <Controller
                          name={`ProgramSchedules[${i}].Price`}
                          control={control}
                          render={({ field }) => (
                            <>
                              <Input
                                {...field}
                                className="v-money form-control bg-white w-75"
                                id={`ProgramSchedules[${i}].Price`}
                                type="number"
                              />
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {errors?.ProgramSchedules?.message ? (
                <FormFeedback
                  type="invalid"
                  className="d-block alert alert-danger"
                >
                  {errors?.ProgramSchedules?.message}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
      </FormProvider>
    </PageModal>
  )
}

ProgramModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default ProgramModal

export const EnumObjectTypes = {
  Staff: 1,
  Adult: 2,
  Children: 3,
  Class: 4,
  CenterAdmin: 5,
}

export const EnumRoleTypes = {
  Host: 1,
  Center_Owner: 2,
  Center_Director: 3,
  Accountant: 4,
  Teaching_Staff: 5,
  Non_Teaching_Staff: 6,
}

export const EnumRoles = {
  Super_Admin: 1,
  Staff: 2,
  Adult: 3,
}

export const EnumServiceTypes = {
  Enroll_In_Program: 1,
  Hourly_Visit: 2,
  Daily_Visit: 3,
  Recurring_Visit: 4,
  Add_Charge: 5,
}

import React, { useState, useEffect } from "react"

//controls
import { Button, Col, Form, Input, Label, Row, FormFeedback } from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//navigation
import { Link, useNavigate, useParams } from "react-router-dom"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

import {
  addNewMeal as onAddNewMeal,
  updateMeal as onUpdateMeal,
  getClasses as onGetClasses,
  resetMeal as onResetMeal,
  getMealTypes as onGetMealTypes,
} from "store/actions"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import Spinners from "components/Common/Spinner"

function BasicInfoTab({ meal, loading }) {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(loading)
  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    Title: yup
      .string()
      .required("Required")
      .min(3, "Title must be at least 3 characters"),
    ClassIDs: yup.array().required("Required"),
    MealTypes: yup.object().required("Required"),
    IsActive: yup.bool(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      Title: "",
      ClassIDs: [],
      IsActive: true,
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    function updateMealState() {
      //reset form with server data
      reset(meal)
    }
    dispatch(onGetMealTypes())
    updateMealState()
  }, [dispatch])

  //////////////////ClassType STATE/////////////////////
  const { classes } = useSelector(state => state.class1)
  ///////////////////////////////////////

  //////////////////Helper STATE/////////////////////
  const { mealtypes } = useSelector(state => state.helper)
  ///////////////////////////////////////

  useEffect(() => {
    return () => {
      dispatch(onResetMeal())
    }
  }, [dispatch])

  const onCancelClick = () => {
    navigate("/meals")
  }

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      const { id } = routeParams

      if (id === "new") {
        dispatch(onAddNewMeal(getValues()))
      } else {
        dispatch(onUpdateMeal(getValues()))
      }
      navigate("/meals")
    }
  }

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  return (
    <FormProvider {...methods}>
      <Row>
        <Col sm="6">
          <div className="mb-3">
            <Label>Title</Label>
            <Controller
              name="Title"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="Title"
                    type="text"
                    required
                    invalid={!!errors.Title}
                  />
                  {errors?.Title?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Title?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Classes</Label>
            <Controller
              name="ClassIDs"
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    id="ClassIDs"
                    options={classes.filter(x => x.RoomID > 0)}
                    getOptionLabel={option => option.Title}
                    getOptionValue={option => option.ClassID}
                    required
                    isMulti
                    onChange={(e, { value }) => {
                      const values = e.map(v =>
                        classes.find(item => item.ClassID == v.ClassID)
                      )
                      setValue("ClassIDs", values)
                    }}
                    aria-invalid={!!errors.ClassIDs}
                    classNamePrefix="select2-selection"
                  />
                  {errors?.ClassIDs?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.ClassIDs?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Meal Type</Label>
            <Controller
              name="MealTypes"
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    id="MealTypes"
                    options={mealtypes}
                    getOptionLabel={option => option.MealType}
                    getOptionValue={option => option.MealTypeID}
                    required
                    aria-invalid={!!errors.MealTypes}
                    classNamePrefix="select2-selection"
                  />
                  {errors?.MealTypes?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.MealTypes?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Active</Label>
            <div>
              <Controller
                name="IsActive"
                control={control}
                render={({ field }) => (
                  <>
                    <Switch
                      {...field}
                      id="IsActive"
                      checked={field.value}
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      className="me-1 mb-sm-8 mb-2"
                      onColor="#626ed4"
                    />
                  </>
                )}
              />
            </div>
          </div>
        </Col>
      </Row>
      <div className="d-flex flex-wrap gap-2">
        <Button color="primary" className="btn" onClick={handleSave}>
          {routeParams.id === "new" ? "Save" : "Update"}
        </Button>
        <Button
          type="submit"
          color="secondary"
          onClick={onCancelClick}
          className=""
        >
          Cancel
        </Button>
      </div>
    </FormProvider>
  )
}
export default BasicInfoTab

import React, { useEffect, useState, Fragment, useMemo } from "react"
import {
  Table,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Moment from "moment"
import { ToastContainer } from "react-toastify"
import { isEmpty } from "lodash"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

import {
  getChildBusSchedules as onGetChildBusSchedules,
  getStaffBusSchedules as onGetStaffBusSchedules,
} from "store/actions"
import Spinners from "components/Common/Spinner"
import Paginations from "components/Common/Pagination"
import { EnumRoles } from "helpers/enum_helper"

//custom hooks
import { useUserDetail } from "hooks"

function BusSchedule() {
  const dispatch = useDispatch()
  const { userprofile } = useUserDetail()
  const [filter, setFilter] = useState({
    date: Moment().format("yyyy-MM-DD"),
    objectID: 0,
    roleID: 0,
  })

  // pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [dataList, setDataList] = useState()
  const perPageData = 5
  const indexOfLast = currentPage * perPageData
  const indexOfFirst = indexOfLast - perPageData

  const updateState = data => setFilter(previous => ({ ...previous, ...data }))

  useEffect(() => {
    updateState({ objectID: userprofile?.UserID, roleID: userprofile?.RoleID })
  }, [userprofile])

  useEffect(() => {
    if (filter.objectID > 0) {
      if (filter.roleID === EnumRoles.Adult) {
        dispatch(onGetChildBusSchedules(filter))
      }
      if (filter.roleID === EnumRoles.Staff) {
        dispatch(onGetStaffBusSchedules(filter))
      }
    }
  }, [filter])

  //////////////////STAFF SCHEDULE STATE/////////////////////
  const selectBusScheduleState = state => state.busSchedules
  const BusScheduleProperties = createSelector(selectBusScheduleState, e => ({
    data: e.busSchedules,
    loading: e.loading,
    column: e.column,
  }))
  const { data, loading, column } = useSelector(BusScheduleProperties)
  const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////

  function handleDate(param) {
    setFilter({ date: param, adultID: userprofile?.UserID })
  }

  const currentdata = useMemo(
    () => data ?? data.slice(indexOfFirst, indexOfLast),
    [data, indexOfFirst, indexOfLast]
  )

  useEffect(() => {
    setDataList(currentdata)
  }, [currentdata])

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  return (
    <Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Schedule" breadcrumbItem="Bus Schedule" />
          <Row className="mb-2">
            <Col sm="12">
              <Card>
                <CardBody>
                  <div className="text-sm-end">
                    <div className="btn-group" role="group">
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio4"
                        autoComplete="off"
                        onClick={() =>
                          handleDate(
                            Moment(filter.date)
                              .subtract(7, "days")
                              .format("yyyy-MM-DD")
                          )
                        }
                      />
                      <label
                        className="btn btn-outline-secondary"
                        htmlFor="btnradio4"
                      >
                        <i className="fas fa-angle-left" />
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio5"
                        autoComplete="off"
                        defaultChecked
                        onClick={() =>
                          handleDate(Moment().format("yyyy-MM-DD"))
                        }
                      />
                      <label
                        className="btn btn-outline-secondary"
                        htmlFor="btnradio5"
                      >
                        Today
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio6"
                        autoComplete="off"
                        onClick={() =>
                          handleDate(
                            Moment(filter.date)
                              .add(7, "days")
                              .format("yyyy-MM-DD")
                          )
                        }
                      />
                      <label
                        className="btn btn-outline-secondary"
                        htmlFor="btnradio6"
                      >
                        <i className="fas fa-angle-right" />
                      </label>
                    </div>
                  </div>
                  <Table className="align-middle table-nowrap table-check table-bordered">
                    <thead className="table-light">
                      <tr>
                        {column.map((row, index) => (
                          <th
                            key={"th" + index}
                            className={row === "BusID" ? "d-none" : ""}
                          >
                            <div className="m-0">{row}</div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {dataList.map((n, index) => {
                        return (
                          <Fragment key={"dl" + index}>
                            <tr style={{ height: "100px" }}>
                              {column.map((v, index) => {
                                return (
                                  <td
                                    key={"clm" + index}
                                    className={
                                      v === "BusID" ? "d-none" : "align-top"
                                    }
                                  >
                                    {/* {console.log("Inner Loop", n[v])} */}
                                    {v !== "Name" &&
                                    v !== "BusID" &&
                                    v !== "id" &&
                                    n[v] !== "" &&
                                    n[v] !== undefined
                                      ? JSON.parse(n[v]).map(w => (
                                          <div
                                            className="border rounded p-2 mb-2 w-100 cursor-pointer"
                                            key={"dv" + w.BusSchedulingID}
                                          >
                                            <div
                                              className="float-end"
                                              onClick={() =>
                                                onClickDelete(w.BusSchedulingID)
                                              }
                                            >
                                              <i className="bx bx-trash bx-sm"></i>
                                            </div>
                                            <h6>
                                              {w.ChildID != null
                                                ? w.ChildName
                                                : w.StaffName}
                                            </h6>
                                            <span className="small text-muted">
                                              {w.ChildID != null
                                                ? "Child"
                                                : w.RoleName}
                                            </span>
                                          </div>
                                        ))
                                      : n[v]}
                                  </td>
                                )
                              })}
                            </tr>
                          </Fragment>
                        )
                      })}
                    </tbody>
                  </Table>
                  {/* <Paginations
                    perPageData={5}
                    data={data}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    isShowingPageLength={false}
                    paginationDiv="col-12"
                    paginationClass="pagination pagination-rounded justify-content-center mt-2 mb-5"
                  /> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  )
}
export default BusSchedule

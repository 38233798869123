import React, { useEffect, useMemo, useState, Suspense } from "react"
import { useDeepCompareEffect } from "hooks"
import PropTypes from "prop-types"
import { Link, json, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"
import HappeningModal from "../modal/HappeningModal"
import JoinClassModal from "../modal/JoinClassModal"
import LeaveClassModal from "../modal/LeaveClassModal"
import {
  getChildHappenings as onGetChildHappenings,
  getHappeningTypes as onGetHappeningTypes,
  deleteChildHappening as onDeleteChildHappening,
} from "store/actions"
import * as moment from "moment"
import Flatpickr from "react-flatpickr"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import Spinners from "components/Common/Spinner"
import { getMinute, getHour } from "helpers/duration_helper"
import DropdownButton from "components/Common/DropdownButton"
import EmptyContainer from "components/Common/EmptyContainer"

function HappeningTab() {
  const [toggleMenu, setToggleMenu] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { childHappenings, child, happeningTypes, loading } = useSelector(
    state => state.child
  )

  const [isLoading, setLoading] = useState(loading)
  const [deleteModal, setDeleteModal] = useState(false)
  const [childHappening, setChildHappening] = useState(null)
  const [happeningTitle, setHappeningTitle] = useState("")

  const [happeningTypeID, setHappeningTypeID] = useState(0)

  const [happeningModal, setHappeningModal] = useState(false)

  const [joinClassModal, setJoinClassModal] = useState(false)
  const [leaveClassModal, setLeaveClassModal] = useState(false)
  const [filterDate, setFilterDate] = useState(
    moment().format(moment().format("DD-MM-yyyy"))
  )
  useDeepCompareEffect(() => {
    dispatch(onGetHappeningTypes())
  }, [dispatch, happeningTypes])

  useEffect(() => {
    dispatch(onGetChildHappenings(child.ChildID, filterDate))
  }, [dispatch, filterDate])

  const onHappeningClick = happeningType => {
    setChildHappening(null)
    setHappeningTypeID(happeningType.HappeningTypeID)
    setHappeningTitle(happeningType.HappeningType)

    if (happeningType.HappeningTypeID === 2) {
      setJoinClassModal(true)
    } else if (happeningType.HappeningTypeID === 3) {
      setLeaveClassModal(true)
    } else {
      setHappeningModal(true)
    }
  }
  const onClickDelete = arg => {
    setChildHappening(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (childHappening && childHappening.ChildHappeningID) {
      dispatch(onDeleteChildHappening(childHappening.ChildHappeningID))
      setDeleteModal(false)
    }
  }
  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM yyyy")
    return date1
  }
  const onClickEdit = arg => {
    setHappeningTitle("Edit " + arg.HappeningType)
    // alert(JSON.stringify(arg))

    if (arg.HappeningTypeID === 2) {
      let childHappening = {
        ...arg,
        Classes: {
          ClassID: arg?.ClassID,
          Title: arg?.Title,
        },
      }
      setChildHappening(childHappening)
      setJoinClassModal(true)
    } else if (arg.HappeningTypeID === 3) {
      setChildHappening(arg)
      setLeaveClassModal(true)
    } else {
      let childHappening = {
        ...arg,
        Adults: {
          ClassID: arg?.PersonID,
          Name: arg?.Person,
        },
        Staffs: {
          StaffID: arg?.PersonID,
          Name: arg?.Person,
        },
      }
      setChildHappening(childHappening)
      setHappeningModal(true)
    }
  }

  const onHappeningModalClose = () => {
    setHappeningModal(false)
    setJoinClassModal(false)
    setLeaveClassModal(false)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Date & time",
        accessor: "HappeningDate",
        Cell: cellProps => {
          return <>{cellProps.row.original.HappeningDate}</>
        },
      },
      {
        Header: "Action",
        accessor: "ShortName",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Details",
        accessor: "Details",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                // to={"/staff/" + cellProps.row.original.RowID}
                className="text-success"
                onClick={() => {
                  onClickEdit(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      {happeningModal && (
        <HappeningModal
          happeningTitle={happeningTitle}
          show={happeningModal}
          childHappening={childHappening}
          onHappeningCloseClick={() => onHappeningModalClose()}
          happeningTypeID={happeningTypeID}
        />
      )}
      {joinClassModal && (
        <JoinClassModal
          happeningTitle={happeningTitle}
          show={joinClassModal}
          childHappening={childHappening}
          onHappeningCloseClick={() => onHappeningModalClose()}
          happeningTypeID={happeningTypeID}
        />
      )}
      {leaveClassModal && (
        <LeaveClassModal
          happeningTitle={happeningTitle}
          show={leaveClassModal}
          childHappening={childHappening}
          onHappeningCloseClick={() => onHappeningModalClose()}
          happeningTypeID={happeningTypeID}
        />
      )}
      <div className="row">
        <div className="col-md-auto">
          <Flatpickr
            className="form-control"
            id="FilterDate"
            options={{
              dateFormat: "d M, Y",
            }}
            value={moment().format("DD MMM yyyy")}
            onChange={(selectedDates, dateStr, instance) => {
              setFilterDate(dateStr)
            }}
          />
        </div>
        <div className="col text-sm-end">
          <DropdownButton
            label="Add"
            items={happeningTypes.filter(x => x.ForChild === true)}
            textField="HappeningType"
            valueField="HappeningTypeID"
            onSelectClick={e => onHappeningClick(e)}
          />
        </div>
      </div>
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <Row>
          <Col xs="12">
            {childHappenings?.length == 0 ? (
              <EmptyContainer
                backURL=""
                message="No happenings found for child"
                linkText=""
              />
            ) : (
              <TableContainer
                columns={columns}
                data={childHappenings}
                isGlobalFilter={true}
                // isAddOption={true}
                //  addOptionText="Add payrate"
                // handleAddClick={handleAddClick}
                customPageSize={10}
                isPagination={true}
                filterable={false}
                iscustomPageSizeOptions={true}
                tableClass="align-middle table-nowrap table-check"
                theadClass="table-light"
                pagination="pagination pagination-rounded justify-content-end mb-2"
              />
            )}
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}
HappeningTab.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default HappeningTab

import {
  GET_PROGRAMS,
  GET_PROGRAMS_FAIL,
  GET_PROGRAMS_SUCCESS,
  DELETE_PROGRAM,
  DELETE_PROGRAM_FAIL,
  DELETE_PROGRAM_SUCCESS,
  ADD_NEW_PROGRAM,
  ADD_PROGRAM_SUCCESS,
  ADD_PROGRAM_FAIL,
  GET_PROGRAM_DETAIL,
  GET_PROGRAM_DETAIL_SUCCESS,
  GET_PROGRAM_DETAIL_FAIL,
  UPDATE_PROGRAM,
  UPDATE_PROGRAM_SUCCESS,
  UPDATE_PROGRAM_FAIL,
  GET_PROGRAM_SCHEDULE_DETAIL,
  GET_PROGRAM_SCHEDULE_DETAIL_FAIL,
  GET_PROGRAM_SCHEDULE_DETAIL_SUCCESS,
  ADD_ENROLLMENT,
  ADD_ENROLLMENT_SUCCESS,
  ADD_ENROLLMENT_FAIL,
  GET_ENROLLMENTS,
  GET_ENROLLMENTS_SUCCESS,
  GET_ENROLLMENTS_FAIL,
  UPDATE_ENROLLMENT,
  UPDATE_ENROLLMENT_SUCCESS,
  UPDATE_ENROLLMENT_FAIL,
  GET_ENROLLMENT_DETAIL,
  GET_ENROLLMENT_DETAIL_SUCCESS,
  GET_ENROLLMENT_DETAIL_FAIL,
} from "./actionTypes"

export const getPrograms = () => ({
  type: GET_PROGRAMS,
})

export const getProgramsSuccess = programs => ({
  type: GET_PROGRAMS_SUCCESS,
  payload: programs,
})

export const getProgramsFail = error => ({
  type: GET_PROGRAMS_FAIL,
  payload: error,
})

export const deleteProgram = programId => ({
  type: DELETE_PROGRAM,
  payload: programId,
})

export const deleteProgramSuccess = program => ({
  type: DELETE_PROGRAM_SUCCESS,
  payload: program,
})

export const deleteProgramFail = error => ({
  type: DELETE_PROGRAM_FAIL,
  payload: error,
})
export const addNewProgram = program => ({
  type: ADD_NEW_PROGRAM,
  payload: program,
})

export const addProgramSuccess = program => ({
  type: ADD_PROGRAM_SUCCESS,
  payload: program,
})

export const addProgramFail = error => ({
  type: ADD_PROGRAM_FAIL,
  payload: error,
})

export const getProgramDetail = programId => ({
  type: GET_PROGRAM_DETAIL,
  programId,
})

export const getProgramDetailSuccess = program => ({
  type: GET_PROGRAM_DETAIL_SUCCESS,
  payload: program,
})

export const getProgramDetailFail = error => ({
  type: GET_PROGRAM_DETAIL_FAIL,
  payload: error,
})

export const updateProgram = program => ({
  type: UPDATE_PROGRAM,
  payload: program,
})

export const updateProgramSuccess = program => ({
  type: UPDATE_PROGRAM_SUCCESS,
  payload: program,
})

export const updateProgramFail = error => ({
  type: UPDATE_PROGRAM_FAIL,
  payload: error,
})

export const getProgramScheduleDetail = (programId, programScheduleId) => ({
  type: GET_PROGRAM_SCHEDULE_DETAIL,
  payload: { programId, programScheduleId },
})

export const getProgramScheduleDetailSuccess = program => ({
  type: GET_PROGRAM_SCHEDULE_DETAIL_SUCCESS,
  payload: program,
})

export const getProgramScheduleDetailFail = error => ({
  type: GET_PROGRAM_SCHEDULE_DETAIL_FAIL,
  payload: error,
})

export const addEnrollment = enrollment => ({
  type: ADD_ENROLLMENT,
  payload: enrollment,
})

export const addEnrollmentSuccess = enrollment => ({
  type: ADD_ENROLLMENT_SUCCESS,
  payload: enrollment,
})

export const addEnrollmentFail = error => ({
  type: ADD_ENROLLMENT_FAIL,
  payload: error,
})

export const getEnrollments = childId => ({
  type: GET_ENROLLMENTS,
  payload: childId,
})

export const getEnrollmentsSuccess = childId => ({
  type: GET_ENROLLMENTS_SUCCESS,
  payload: childId,
})

export const getEnrollmentsFail = error => ({
  type: GET_ENROLLMENTS_FAIL,
  payload: error,
})

export const updateEnrollment = enrollment => ({
  type: UPDATE_ENROLLMENT,
  payload: enrollment,
})

export const updateEnrollmentSuccess = enrollments => ({
  type: UPDATE_ENROLLMENT_SUCCESS,
  payload: enrollments,
})

export const updateEnrollmentFail = error => ({
  type: UPDATE_ENROLLMENT_FAIL,
  payload: error,
})

export const getEnrollmentDetail = programEnrollmentID => ({
  type: GET_ENROLLMENT_DETAIL,
  payload: programEnrollmentID,
})

export const getEnrollmentDetailSuccess = enrollment => ({
  type: GET_ENROLLMENT_DETAIL_SUCCESS,
  payload: enrollment,
})

export const getEnrollmentDetailFail = error => ({
  type: GET_ENROLLMENT_DETAIL_FAIL,
  payload: error,
})
